import { IFilterGQL, TJsonCollectionFilter, TJsonFilter } from "@components/Algolia/algolia.type";
import { IItemProps } from "@components/Algolia/Filters/CustomRefinementList.component";
import { ECategoryFiltersIdentifiers } from "@components/Category/category.query";
import { getContentTranslations } from "@shared/contentful/translations.utils";

interface IGetRefinementAttribute {
	refinement: string;
	identifier: ECategoryFiltersIdentifiers;
	data?: TJsonCollectionFilter;
	locale?: string;
}

export const getRefinementAttribute = ({
	refinement,
	identifier,
	data,
	locale,
}: IGetRefinementAttribute) => {
	let attribute, label;
	const translationsFilters = getContentTranslations(data?.jsonCollection?.items)[identifier];

	if (translationsFilters) {
		const filter = (translationsFilters as TJsonFilter)[refinement];
		if (filter !== undefined && (filter.withLocal !== undefined || filter.withLocal !== null)) {
			attribute = filter.withLocal ? `${filter.value}_${locale}` : filter.value;
		}

		label = filter?.[locale as keyof IFilterGQL] as string;
	}
	return { attribute, label };
};

export const countFilters = (items: IItemProps[]) => {
	return items.reduce((n: number, item: IItemProps) => {
		return n + Number(item.isRefined);
	}, 0);
};
