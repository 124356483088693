import axios from "axios";

import type { IAddressInputData } from "@components/Checkout/Delivery/How/Address/Address.type";
import StatusError from "@utils/StatusError";
import { defaultLocale } from "@config/languages";
import type { ICartItemsScenario, ICartLine } from "@shared/types/cart.types";
import { getUrlSaveAddresses } from "@shared/address/address.utils";

interface ISaveAddressDefault {
	shouldRefreshAddresses: boolean;
}

export const setDefaultAddress = async (
	address: IAddressInputData,
	callbackAfterDefault: () => void
) => {
	try {
		const { data } = await axios.post<ISaveAddressDefault>("/api/addresses/edit-address-default", {
			address,
		});

		if (data?.shouldRefreshAddresses) callbackAfterDefault();
	} catch (error) {
		console.error(error);
	}
};

export interface ISaveAddress extends IAddressInputData {
	is_address: boolean;
}

export const saveAddress = async (address: IAddressInputData) => {
	const urlApi = getUrlSaveAddresses(address.address_id);

	try {
		if (!address?.pincode) throw new StatusError(400, "postal code is missing");
		address.pincode = address.pincode.replace(/\s*/g, "");
		const { data } = await axios.post<ISaveAddress>(urlApi, { address });
		if (data) {
			return data;
		} else {
			throw new StatusError(500, "Something went wrong while updating the address");
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

export const redirectUserNotConnected = (locale = defaultLocale) => {
	return {
		redirect: {
			destination: `/${locale}/cart`,
		},
		props: {},
	};
};

export const getCartItemsScenario = (cartLines: ICartLine[]): ICartItemsScenario[] =>
	cartLines.map((item) => {
		const { quantity, itemCode, unitPrice, sku } = item || {};
		return {
			quantity,
			sku_id: sku,
			option_id: itemCode,
			fulfiller_id: "dkt-fulfiller-ca",
			price: { price_id: itemCode, unit_price: unitPrice },
		};
	});
