import { createAsyncThunk } from "@reduxjs/toolkit";
import { selectScenariosStore } from "../scenarios.selector";
import { RootState } from "@redux/reducer";
import axios from "axios";
import { IContainer } from "@shared/types/containers.types";
import {
	isNonHydratedContainer,
	isNonHomologated,
	isPickupPoint,
} from "@shared/scenarios/scenarios.utils";

const getExpandedParcel = createAsyncThunk<
	{ expandedParcel?: number; container?: IContainer },
	{ parcelIndex: number },
	{ rejectValue: { error: string; items?: string[] }; state: RootState }
>("cart/getExpandedParcel", async ({ parcelIndex }, { rejectWithValue, getState }) => {
	const { expandedParcel, data: scenarioData } = selectScenariosStore(getState());

	if (!scenarioData || isNonHomologated(scenarioData)) {
		return rejectWithValue({ error: "Oops there seems to be an error" });
	}

	const updatedExpandedParcel = expandedParcel === parcelIndex ? undefined : parcelIndex;

	try {
		const container = scenarioData.containers[parcelIndex];

		if (
			!isNonHydratedContainer(container) &&
			container.pickup?.[0] &&
			isPickupPoint(container.pickup?.[0])
		) {
			const { data = [] } = await axios.post("/api/scenario/get-pickup-points", {
				containerId: container.container_id,
				deliveryId: container.pickup?.[0]?.delivery_id,
				offerId: container.offer_id,
				scenarioId: scenarioData.scenarioId,
				shippingId: scenarioData.shippingId,
				deliverySlotId: container.pickup?.[0]?.delivery_slot_id,
			});

			return {
				expandedParcel: updatedExpandedParcel,
				container: { ...container, pickup: data },
			};
		}

		const scenarioInfos = {
			scenarioId: scenarioData.scenarioId,
			offerId: scenarioData.offerId,
			shippingId: scenarioData.shippingId,
			items: scenarioData.scenarioItems,
		};

		const { data } = await axios.post("/api/scenario/retrieve-parcel", {
			scenarioContainer: container,
			scenarioInfos,
		});

		return {
			expandedParcel: updatedExpandedParcel,
			container: data,
		};
	} catch (error) {
		return rejectWithValue({ error: "Oops there seems to be an error" });
	}
});

export default getExpandedParcel;
