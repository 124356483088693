import { EVendor } from "@shared/cookie-consent/cookie-consent.utils";
import {
	ESessionStorageName,
	getSessionStorageItem,
	isSessionStorageItemExists,
	removeSessionStorageItem,
} from "./sessionStorage.utils";
import { ECookieName, deleteCookie } from "./cookie.utils";
import { IDidomiObject } from "@didomi/react";

export const shouldRemoveHitLastViewed = () => {
	if (!isSessionStorageItemExists(ESessionStorageName.HitLastViewed)) {
		return;
	}

	const { productCardReferer } = getSessionStorageItem(ESessionStorageName.HitLastViewed, "object");
	const currentURL = window.location.href;

	if (
		productCardReferer &&
		productCardReferer !== currentURL &&
		document.referrer !== productCardReferer
	) {
		removeSessionStorageItem(ESessionStorageName.HitLastViewed);
	}
};

export const deleteCookieVendors = (didomi: IDidomiObject) => {
	didomi
		.getObservableOnUserConsentStatusForVendor(`c:${EVendor.Algolia}`)
		.subscribe((consentStatusForVendor: boolean) => {
			if (!consentStatusForVendor) {
				deleteCookie(ECookieName.AlgoliaUserToken);
			}
		});
};
