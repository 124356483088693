import { THydrateAction } from "@redux/reducer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IParcel } from "@shared/types/containers.types";
import { INonHomologatedScenario } from "@shared/types/scenarios.types";
import { HYDRATE } from "next-redux-wrapper";
import getScenario from "./use-case/scenario.use-case";
import getExpandedParcel from "./use-case/scenario-expanded-parcel.use-case";
import { isNonHomologated } from "@shared/scenarios/scenarios.utils";

export interface IGetScenarios {
	data?: IParcel | INonHomologatedScenario;
	expandedParcel?: number;
	errorType?: string | null;
	errors?: boolean;
}

const initialState: IGetScenarios = {
	expandedParcel: 0,
};

const { actions, reducer } = createSlice({
	name: "scenarios",
	initialState,
	reducers: {
		setScenario: (state, action: PayloadAction<IParcel | INonHomologatedScenario>) => {
			if (!action.payload) return state;

			return {
				...state,
				data: action.payload,
			};
		},
		setErrors: (state, action: PayloadAction<boolean>) => {
			state.errors = action.payload;
		},
		closeExpandedParcel: (state) => {
			state.expandedParcel = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getScenario.fulfilled, (state, { payload }) => {
				state.data = payload;
				state.expandedParcel = 0;
			})
			.addCase(getScenario.rejected, (state, { payload }) => {
				if (payload && "items" in payload) state.data = { ...payload, items: payload.items ?? [] };

				state.errors = true;
			})
			.addCase(getExpandedParcel.fulfilled, (state, { payload }) => {
				const expandedParcelPayload = payload.expandedParcel;

				state.expandedParcel = expandedParcelPayload;

				if (
					expandedParcelPayload !== undefined &&
					payload.container &&
					state.data &&
					!isNonHomologated(state.data)
				) {
					state.data.containers[expandedParcelPayload] = payload.container;
				}
			})
			.addCase(HYDRATE, (state: IGetScenarios, { payload }: THydrateAction) => {
				return { ...state, ...payload.scenarios };
			});
	},
});

export const { setScenario, setErrors, closeExpandedParcel } = actions;

export default reducer;
