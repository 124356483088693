export const replaceNonBreakingSymbol = (text: string) => {
	//Sometimes the tooltip content from contentful has non breaking space unicode characters in the string.
	//We need to replace with normal space to prevent overflow of the tooltip when using word-break: normal.
	return text?.replace(/\u00A0/g, " ").replace(/&nbsp;/g, "\u00A0");
};

export const replaceThinNonBreakSpace = (text: string) => text?.replace(/&thinsp;/g, "\u2009");

export const replaceAllBreakSpaces = (text: string) => {
	const nonBreakingSymbolText = replaceNonBreakingSymbol(text);
	return replaceThinNonBreakSpace(nonBreakingSymbolText);
};

export const isStringList = (text: string) => {
	return Boolean(text?.indexOf(",") >= 0);
};

export const convertStringListIntoArray = (text: string) => {
	if (!text) return;
	return isStringList(text) ? text.split(",") : text;
};
