import { IProductInfo, selectCartProductsInfo } from "@redux/cart/cart-content.selector";
import { RootState } from "@redux/reducer";
import { createSelector } from "@reduxjs/toolkit";
import { isNonHomologated } from "@shared/scenarios/scenarios.utils";
import { IItem } from "@shared/types/containers.types";
import { IProductImage } from "@shared/types/product.types";

export const selectScenariosStore = (store: RootState) => store.scenarios;

export const selectExpandedParcel = createSelector(
	selectScenariosStore,
	(state) => state?.expandedParcel
);

export const selectContainerScenarios = createSelector(selectScenariosStore, (state) =>
	state?.data && "containers" in state.data ? state.data.containers : []
);

export const formatProductImages = (
	productInfos: IProductInfo[],
	containers: { items: IItem[] }[]
): IProductImage[][] =>
	containers.map((container) => {
		return (
			container?.items?.map((item, index) => {
				const {
					imageUrl: url = "",
					brand,
					selectedArticleInfo,
				} = productInfos?.find(
					(productInfo) =>
						item?.option_id !== undefined && `${productInfo?.itemCode}` === item.option_id
				) || {};

				const { item_id: id } = item;

				return { url, id, brand, name: selectedArticleInfo?.itemName, imageOrder: index };
			}) || []
		);
	});

export const selectContainerProductInfos = createSelector(
	selectCartProductsInfo,
	selectContainerScenarios,
	formatProductImages
);

export const selectScenarioId = createSelector(selectScenariosStore, (scenario) => {
	const dataScenario = scenario.data;
	if (dataScenario && !isNonHomologated(dataScenario)) return dataScenario.scenarioId;
});

export const selectScenarioHasError = createSelector(selectScenariosStore, ({ errors }) => errors);
