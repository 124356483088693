import axios from "axios";
import { defaultLocale } from "@config/languages";
import { extractArticleSkus } from "@shared/product/product.utils";
import {
	IGetModelIdListInfo,
	IGetRecommendedDataWithAvailability,
} from "@shared/recommendation/recommendation.types";
import { IProduct, IProductWithAvailability } from "@shared/types/product.types";
import { IAvailabilityProduct } from "@shared/availability/types";

export const isRecommendedProductAvailable = (
	availabilityArticles?: (IAvailabilityProduct | undefined)[]
) => {
	return availabilityArticles?.some((article) => article && article.quantity > 0) ?? false;
};

export const getModelIdListInfo = async ({
	productList,
	locale = defaultLocale,
}: IGetModelIdListInfo): Promise<IProduct[] | undefined> => {
	if (productList?.length) {
		const { data: productData } = await axios.get("/api/product/get-product", {
			params: {
				modelId: String(productList),
				locale,
			},
		});

		if (!productData || productData.error) return;
		return Array.isArray(productData) ? productData : [productData];
	}
};

export const getRecommendationDataWithAvailability = async ({
	crossSellingData,
}: IGetRecommendedDataWithAvailability): Promise<IProductWithAvailability[] | undefined> => {
	if (!crossSellingData) return;

	try {
		return await Promise.all(
			crossSellingData.map(async (product: IProduct) => {
				const { data: availability } = await axios.get<IAvailabilityProduct[]>(
					"/api/availability",
					{
						params: {
							skus: extractArticleSkus(product.articles),
						},
					}
				);
				const isAvailable = isRecommendedProductAvailable(availability);
				return { product, isAvailable };
			})
		);
	} catch (error) {
		return crossSellingData.map((product) => ({ product, isAvailable: false }));
	}
};

export const filterByAvailability = (
	listOfProducts: IProductWithAvailability[],
	numberOfItems = 10
) => {
	if (!listOfProducts?.length) return;
	return listOfProducts.filter((item) => item.isAvailable).slice(0, numberOfItems);
};
