import axios from "axios";
import { RootState } from "@redux/reducer";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICartData } from "@shared/types/cart.types";
import { extractCartErrors } from "@shared/cart/cart.utils";
import getScenario from "@redux/scenarios/use-case/scenario.use-case";
import { getCartItemsScenario } from "@shared/checkout/checkout.utils";
import { ECartErrorType, ICartError } from "@components/Cart/CartProvider/CartProvider.component";
import { setCartItemsCountInCookies } from "@shared/cart/cookies.utils";

const getCart = createAsyncThunk<
	{ data?: ICartData; cartErrors?: ICartError[]; loading?: boolean } | undefined,
	{ locale: string; isRefreshScenario?: boolean },
	{ rejectValue: ICartError[]; state: RootState }
>("cart/getCart", async ({ locale, isRefreshScenario }, { rejectWithValue, dispatch }) => {
	try {
		const { data } = await axios.get<ICartData>("/api/cart/view-cart", {
			params: { locale },
			headers: {
				"Cache-Control": "no-cache",
			},
		});

		if (data?.cartLines?.length && data.cartId) {
			const { cartLines, cartId } = data;
			const cartErrors = extractCartErrors(cartLines);

			isRefreshScenario &&
				(await dispatch(
					getScenario({
						cartInfos: { cartId, items: getCartItemsScenario(cartLines) },
						locale,
					})
				));

			return { data, cartErrors };
		} else {
			setCartItemsCountInCookies(0);
			if (!window.location.href.includes("/cart")) {
				window.location.href = `/${locale}/cart`;
			}
			return { loading: true };
		}
	} catch {
		return rejectWithValue([{ type: ECartErrorType.ErrorMessageBadReturnApi }]);
	}
});

export default getCart;
