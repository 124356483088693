import { isEqual } from "lodash";
import { ICartContextData, ICartError, TCartContextData } from "./CartProvider.component";

export enum ECartContextActionsType {
	UpdateCart = "updateCart",
	SetError = "setError",
	ClearError = "clearError",
	SetLoading = "setLoading",
}

export interface ICartContextActions {
	type: ECartContextActionsType;
	payload?: TCartContextData | ICartError;
}

const initCartState = {
	cartInfo: {
		orderSummary: {
			cartTotal: 0,
			subTotal: 0,
			shippingCost: 0,
			totalTaxAmount: 0,
		},
		cartId: "",
		cartLines: [],
		totalLineCount: 0,
		fulfillmentOptionId: null,
		addresses: [],
		postalCode: "",
	},
	isLoading: false,
	loaderCount: 0,
	recommendationData: [],
};

export const initCartContext = {
	state: initCartState,
	dispatch: () => ({}),
	requireRefresh: () => ({}),
	setLatestItemSavedForLater: () => ({}),
};

const clearError = (
	contextValue: ICartContextData,
	itemCode: number | number[] | undefined
): ICartContextData => {
	let errors;
	if (!itemCode) {
		errors = contextValue.errors?.filter((error) => error?.data);
	} else {
		errors = contextValue.errors?.filter((error) => !isEqual(error?.data, itemCode));
	}
	return { ...contextValue, errors, isLoading: false };
};

export const cartContextReducer = (contextValue: ICartContextData, action: ICartContextActions) => {
	switch (action.type) {
		case ECartContextActionsType.UpdateCart:
			return {
				...contextValue,
				cartInfo: action.payload as TCartContextData,
				isLoading: false,
			};
		case ECartContextActionsType.SetError:
			return {
				...contextValue,
				errors: [...(contextValue.errors ?? []), action.payload as ICartError],
				isLoading: false,
			};
		case ECartContextActionsType.ClearError:
			return clearError(contextValue, (action.payload as ICartError)?.data);
		case ECartContextActionsType.SetLoading:
			return {
				...contextValue,
				isLoading: true,
				loaderCount: contextValue.loaderCount + 1,
			};
		default:
			return contextValue;
	}
};
