import App, { AppContext, AppProps as NextAppProps } from "next/app";
import getConfig from "next/config";
import Head from "next/head";
import KlarnaSdk from "@components/Klarna/KlarnaSdk.component";
import { Router } from "next/router";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { SessionProvider } from "next-auth/react";
import { wrapper } from "@redux/store";
import { setReferer, setCookieConsent } from "@redux/misc/misc.reducer";
import { selectMiscIsScrollable } from "@redux/misc/misc.selector";
import { buildGtmCode } from "@shared/datalayer/app-datalayer/app-datalayer";
import { deleteCookieVendors, shouldRemoveHitLastViewed } from "@utils/app.utils";
import { getRegion } from "@utils/province.utils";
import "@shared/styles/tailwind.scss";
import "@shared/styles/lightgallery.css";
import "@shared/styles/didomi.scss";
import "@components/Carousel/Carousel.scss";
import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";
import SignInError from "@components/SignInError/SignInError.component";
import { Session } from "next-auth";
import DataLayer, { globalDataLayer } from "@shared/datalayer/DataLayer";
import { LayoutProvider } from "@components/Layout/Layout.context";
import { province } from "@config/misc";
import { datadogRum } from "@datadog/browser-rum";
import { nanoid } from "nanoid";
import { IStatsigUserProps } from "@shared/statsig/statsig.types";
import { DidomiSDK } from "@didomi/react";
import useLocale from "@hooks/useLocale";
import { EFeatureFlagIdentifier } from "@shared/featureFlag/featureFlag.query";
import { didomiConfig } from "@shared/cookie-consent/cookie-consent.utils";
import { useFeatureFlag } from "@hooks/useFeatureFlag";
import { ECookieName, deleteCookie } from "@utils/cookie.utils";
import Statsig from "@components/Statsig/Statsig.container";

const {
	publicRuntimeConfig: {
		datadog,
		didomi: { noticeId },
		clickStreamSdk: { url: clickStreamUrl },
	},
} = getConfig();

interface IPageProps {
	session: Session;
}

datadog.enabled &&
	datadogRum.init({
		applicationId: datadog.applicationId,
		clientToken: datadog.clientToken,
		site: datadog.site,
		service: datadog.service,
		env: datadog.env,
		version: datadog.version,
		sessionSampleRate: datadog.sessionSampleRate,
		sessionReplaySampleRate: datadog.sessionReplaySampleRate,
		trackUserInteractions: datadog.trackUserInteractions,
		trackResources: datadog.trackResources,
		trackLongTasks: datadog.trackLongTasks,
		defaultPrivacyLevel: datadog.defaultPrivacyLevel,
	});

export interface IAppProps extends NextAppProps<IPageProps> {
	gtm: { gtmId: string; gtmAuth: string; gtmPreview: string };
	refreshToken: string;
	hasRefreshToken: boolean;
	referer: string | undefined;
	pageProps: IPageProps;
	user: IStatsigUserProps;
}

export const Decathlon = ({
	Component,
	pageProps: { session, ...pageProps },
	gtm,
	referer,
	user,
}: IAppProps) => {
	const dispatch = useDispatch();

	const isScrollable = useSelector(selectMiscIsScrollable);
	const { locale } = useLocale();
	const [isRouteUpdated, setIsRouteUpdated] = useState(false);

	const { gtmId, gtmAuth, gtmPreview } = gtm;

	if (!globalDataLayer.customDataLayer) {
		globalDataLayer.customDataLayer = new DataLayer();
	}

	useEffect(() => {
		if (referer) dispatch(setReferer(referer));
		shouldRemoveHitLastViewed();
	}, []);

	useEffect(() => {
		const handleRouteChange = () => {
			setIsRouteUpdated(true);
		};

		Router.events.on("routeChangeComplete", handleRouteChange);
		setIsRouteUpdated(true);
		return () => {
			Router.events.off("routeChangeComplete", handleRouteChange);
		};
	}, []);

	const isCookieConsentEnabled = useFeatureFlag(EFeatureFlagIdentifier.CookieConsent);

	useEffect(() => {
		if (isCookieConsentEnabled === false) {
			deleteCookie(ECookieName.AlgoliaUserToken);
		}
	}, [isCookieConsentEnabled]);

	return (
		<>
			<Head>
				<meta charSet="utf-8" />
			</Head>
			<Helmet
				bodyAttributes={{
					class: `${isScrollable ? "scroll" : "noScroll"}`,
				}}
			/>
			<>
				<KlarnaSdk />
				<script
					data-testid="App_click-stream-sdk"
					src={clickStreamUrl}
					type="text/javascript"
					defer
					async
				/>
				{gtmId && (
					<>
						<script
							defer={false}
							async={false}
							dangerouslySetInnerHTML={buildGtmCode({ gtmAuth, gtmId, gtmPreview })}
							data-testid="App_google-tag-manager"
						/>
						{isRouteUpdated && (
							<script
								data-testid="App_google-tag-manager-reset"
								async={true}
								defer={true}
								dangerouslySetInnerHTML={{
									__html: `if(window.google_tag_manager){window.google_tag_manager['${gtmId}'].dataLayer.reset();} else {console.warn('Google tag manager could not be initialized. GTM_ID: ${gtmId}')}`,
								}}
							/>
						)}
					</>
				)}

				{isCookieConsentEnabled && (
					<DidomiSDK
						noticeId={noticeId}
						config={didomiConfig(locale)}
						iabVersion={2}
						gdprAppliesGlobally
						onConsentChanged={() => dispatch(setCookieConsent(true))}
						onReady={(didomi) => deleteCookieVendors(didomi)}
					/>
				)}

				<Statsig user={user}>
					<SessionProvider session={session}>
						<SignInError />
						<LayoutProvider>
							<Component {...pageProps} />
						</LayoutProvider>
					</SessionProvider>
				</Statsig>
			</>
		</>
	);
};

Decathlon.getInitialProps = async (appContext: AppContext) => {
	const { gtmId, gtmAuth, gtmPreview, host } = getConfig().serverRuntimeConfig;
	const {
		ctx: { req, res, locale },
	} = appContext;

	const statusCode = res?.statusCode;
	const absoluteUrl = `${host}/${locale}`;

	const appProps = await App.getInitialProps(appContext);

	const randomID = nanoid();
	const user = { userID: randomID };

	return {
		...appProps,
		pageProps: {
			region: req?.headers?.host ? await getRegion(req) : province,
			statusCode,
			absoluteUrl,
			user,
		},
		gtm: {
			gtmId,
			gtmAuth,
			gtmPreview,
		},
		referer: req?.headers?.referer,
	};
};

export default wrapper.withRedux(Decathlon);
