import { gql } from "graphql-request";

export const componentRichTextFragment = gql`
	fragment richText on ContentTypeRichText {
		__typename
		sys {
			id
		}
		identifier
		displayAs
		openLinksInANewTab
		richTextContent {
			json
		}
	}
`;
