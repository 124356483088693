import { ICartProduct } from "@shared/cart/cart.types";
import { setCartItemsCountInCookies } from "@shared/cart/cookies.utils";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICartState {
	latestAddedProduct?: ICartProduct;
	itemsCount: number;
	loaderQueue: number;
	loaderReady: number;
	isLoading: boolean;
}

const initialState: ICartState = {
	itemsCount: 0,
	loaderQueue: 0,
	loaderReady: 0,
	isLoading: false,
};

const { actions, reducer } = createSlice({
	name: "cart",
	initialState,
	reducers: {
		addProductToCart(state, action: PayloadAction<ICartProduct>) {
			return {
				...state,
				latestAddedProduct: action.payload,
			};
		},
		setItemsCount(state, action: PayloadAction<number>) {
			setCartItemsCountInCookies(action.payload);
			return {
				...state,
				itemsCount: action.payload,
			};
		},
		setQueue(state) {
			return {
				...state,
				loaderQueue: state.loaderQueue + 1,
				isLoading: true,
			};
		},
		setReady(state) {
			const { loaderQueue, loaderReady } = state;

			return {
				...state,
				loaderReady: loaderReady + 1,
				isLoading: loaderQueue !== loaderReady + 1,
			};
		},
	},
});

export type TCartActions =
	| ReturnType<typeof addProductToCart>
	| ReturnType<typeof setItemsCount>
	| ReturnType<typeof setQueue>
	| ReturnType<typeof setReady>;

export const { addProductToCart, setItemsCount, setQueue, setReady } = actions;
export default reducer;
