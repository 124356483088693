import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IProductState {
	size: string;
	sku: string;
	articleId: number;
	isErrorSize: boolean;
	hasReviews: boolean;
}

const initialState: IProductState = {
	size: "",
	sku: "",
	articleId: 0,
	isErrorSize: false,
	hasReviews: false,
};

const { actions, reducer } = createSlice({
	name: "product",
	initialState,
	reducers: {
		setProductSize(
			state,
			action: PayloadAction<Pick<IProductState, "articleId" | "size" | "sku">>
		) {
			return {
				...state,
				...action.payload,
				isErrorSize: false,
			};
		},
		setProductErrorSize(state) {
			return {
				...state,
				isErrorSize: true,
			};
		},
		setProductReviews(state, action: PayloadAction<boolean>) {
			return {
				...state,
				hasReviews: action.payload,
			};
		},
	},
});

export type TProductActions =
	| ReturnType<typeof setProductSize>
	| ReturnType<typeof setProductErrorSize>
	| ReturnType<typeof setProductReviews>;

export const { setProductSize, setProductErrorSize, setProductReviews } = actions;
export default reducer;
