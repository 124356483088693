import { createSlice } from "@reduxjs/toolkit";
import submitCheckout from "./use-case/submitCheckout.use-case";
import { TCheckoutError } from "./type/checkout.type";

export interface ICheckoutState {
	loaderQueue: number;
	loaderReady: number;
	isLoading: boolean;
	loaderCount: number;
	error?: TCheckoutError;
}

const initialState: ICheckoutState = {
	loaderQueue: 0,
	loaderReady: 0,
	isLoading: false,
	loaderCount: 0,
};

const { actions, reducer } = createSlice({
	name: "checkout",
	initialState,
	reducers: {
		setQueue(state) {
			return {
				...state,
				loaderQueue: state.loaderQueue + 1,
				isLoading: true,
			};
		},
		setReady(state) {
			const { loaderQueue, loaderReady, loaderCount } = state;

			return {
				...state,
				loaderReady: loaderReady + 1,
				isLoading: loaderQueue !== loaderReady + 1,
				loaderCount: loaderCount + 1,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(submitCheckout.rejected, (state, { payload }) => {
				state.error = payload;
				state.isLoading = false;
			})
			.addCase(submitCheckout.pending, (state) => {
				state.isLoading = true;
			});
	},
});

export type TCheckoutActions = ReturnType<typeof setQueue> | ReturnType<typeof setReady>;

export const { setQueue, setReady } = actions;
export default reducer;
