import { Dispatch, SetStateAction } from "react";
import { IPostalCode } from "@shared/checkout/delivery.types";
import { EKeyboard } from "@shared/types/misc.types";
import { ECookieName, getCookie, TCookieContextRequest } from "./cookie.utils";
import { postalCode } from "@config/misc";

interface IValidateInputOnBlur {
	value: string;
	translation: string;
	setError: Dispatch<SetStateAction<string | undefined>>;
}

interface IHandleInputChange
	extends Pick<IPostalCode, "setPostalCodeValue" | "postalCodeValidator" | "setErrorMessageApi"> {
	target: EventTarget & HTMLInputElement;
	setChangedValue: Dispatch<SetStateAction<string | number | readonly string[] | undefined>>;
	setSelectionStart: Dispatch<SetStateAction<number | null>>;
}

interface IHandleKeyDown {
	key: string;
	setIsRemoveKeyPressed: Dispatch<SetStateAction<boolean>>;
}

export const getPostalCodeGeo = (appCtx?: TCookieContextRequest): string => {
	return getCookie(ECookieName.PostalCodeGeo, appCtx)?.replace(/\s+/g, "") || postalCode;
};

export const formatPostalCode = (postalCode: string, separator?: string) => {
	if (!postalCode) {
		return "";
	}

	const uppercasedArray = postalCode.toUpperCase().replaceAll(/\s/g, "").split("");

	if (uppercasedArray[3]) {
		uppercasedArray.splice(3, 0, separator ?? "\u00A0");
	}

	return uppercasedArray.join("");
};

export const isValidCanadianPostalCode = (value: string) => {
	const regex = /^(?!.*[DFIOQU])[A-VXY]\d[A-Z](\s?\d[A-Z]\d)?$/;
	return new RegExp(regex).test(value.toUpperCase().trim());
};

export const validateInputOnBlur = ({ value, translation, setError }: IValidateInputOnBlur) => {
	if (isValidCanadianPostalCode(value)) {
		setError(undefined);
	} else {
		setError(translation);
	}
};

export const handleInputChange = ({
	setSelectionStart,
	target,
	setPostalCodeValue,
	postalCodeValidator: { setIsValid },
	setErrorMessageApi,
	setChangedValue,
}: IHandleInputChange) => {
	const postalCode = formatPostalCode(target.value);
	setPostalCodeValue(postalCode);
	setChangedValue(target.value);
	setSelectionStart(target.selectionStart);
	const isValid = isValidCanadianPostalCode(postalCode);
	setErrorMessageApi?.("");
	setIsValid(isValid);
};

export const handleKeyDown = ({ key, setIsRemoveKeyPressed }: IHandleKeyDown) =>
	(key === EKeyboard.Backspace || key === EKeyboard.Delete) && setIsRemoveKeyPressed(true);
