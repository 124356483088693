import { gql } from "graphql-request";

export const componentLinkFragment = gql`
	fragment link on Link {
		__typename
		sys {
			id
		}
		label
		url
		options
		identifier
		relOptions
		targetOptions
		type
	}
`;

export const componentLinkWithIconFragment = gql`
	${componentLinkFragment}
	fragment linkIcon on Link {
		...link
		icon
	}
`;
