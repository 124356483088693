import { createSlice } from "@reduxjs/toolkit";
import getScenario from "@redux/scenarios/use-case/scenario.use-case";
import getExpandedParcel from "@redux/scenarios/use-case/scenario-expanded-parcel.use-case";

export interface ILoaderCheckoutState {
	isLoading: boolean;
}

const initialState: ILoaderCheckoutState = {
	isLoading: false,
};

const { reducer } = createSlice({
	name: "loaderCheckout",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getScenario.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getScenario.fulfilled, (state) => {
			state.isLoading = false;
		});
		builder.addCase(getScenario.rejected, (state) => {
			state.isLoading = false;
		});
		builder.addCase(getExpandedParcel.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getExpandedParcel.fulfilled, (state) => {
			state.isLoading = false;
		});
		builder.addCase(getExpandedParcel.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export default reducer;
