import { TJsonCollectionFilter } from "@components/Algolia/algolia.type";
import { ECategoryFiltersIdentifiers } from "@components/Category/category.query";
import { TAlgoliaHit } from "@shared/algolia/algolia.types";
import { getProvinceGeoCookie } from "@utils/province.utils";
import { getRefinementAttribute } from "@utils/refinements.utils";
import { isEmpty } from "lodash";
import {
	EDataLayerEventType,
	EDataLayerPageType,
	IBuildDataLayerResultPageArgs,
	IDataLayerFiltersArgs,
	TAppliedFilters,
} from "../datalayer.types";
import { buildDataLayerCurrency } from "../layout-datalayer/layout-datalayer";
import { EAvailabilityStatus } from "@shared/availability/types";

const filtersMatch: Record<string, string> = {
	manufacturer_en: "brand",
	size_en: "size",
	nature_en: "type",
	gender_en: "gender",
	manufacturer_fr: "brand",
	size_fr: "size",
	nature_fr: "type",
	gender_fr: "gender",
	generic_color: "color",
};

export const stockStatus = (quantity: number) => {
	return quantity > 0 ? EAvailabilityStatus.InStock : EAvailabilityStatus.OutOfStock;
};

// SEARCH & CATEGORY
export const buildDataLayerProductImpressions = (hits: TAlgoliaHit[], offset = 0) => {
	return hits?.map((hit, index) => {
		return {
			position: offset + index + 1,
			id: hit?.item_code?.toString(),
			supermodel: {
				id: hit?.dsm,
			},
			model: {
				id: hit?.model_id?.toString(),
			},
			availability: {
				warehouse: stockStatus(hit?.inventory),
			},
			price: {
				currency: hit?.currency,
				original: hit?.price_nodiscount?.toString(),
				current: hit?.price?.toString(),
			},
			brand: hit?.manufacturer_en,
		};
	});
};

export const buildDataLayerResultsPage = (
	isCategoryPage: boolean,
	{
		categoryName,
		content,
		initialPriceRange,
		locale,
		canonical,
		translationsFiltersColors,
		breadcrumbs,
	}: IBuildDataLayerResultPageArgs
) => {
	const filters = buildDataLayerFilters({
		content,
		locale,
		translationsFiltersColors,
		initialPriceRange,
	});
	const region = getProvinceGeoCookie();

	let dataLayerBreadcrumbs;
	try {
		dataLayerBreadcrumbs = JSON.parse(breadcrumbs as string);
	} catch {
		dataLayerBreadcrumbs = undefined;
	}

	return {
		event: EDataLayerEventType.PageView,
		page: {
			type: isCategoryPage ? EDataLayerPageType.Category : EDataLayerPageType.Search,
			region,
			language: { name: locale },
			currency: buildDataLayerCurrency(),
			canonical,
			...(isCategoryPage ? { categoryTitle: categoryName, breadcrumb: dataLayerBreadcrumbs } : {}),
			...(filters ? { filters } : {}),
		},
	};
};

export const getColorName = (
	hexCodes: string[],
	locale: string,
	translationsFiltersColors: TJsonCollectionFilter
) => {
	const ArrayColorName: string[] = [];

	hexCodes?.forEach((hexCode: string) => {
		const { label } = getRefinementAttribute({
			data: translationsFiltersColors,
			refinement: hexCode,
			identifier: ECategoryFiltersIdentifiers.JsonCategoryFilterColorTranslations,
			locale,
		});

		if (label) ArrayColorName.push(label);
	});
	return ArrayColorName;
};

export const buildDataLayerOtherFilters = (
	locale: string,
	translationsFiltersColors?: TJsonCollectionFilter,
	refinementList?: Record<string, string[] | undefined>
) => {
	if (!refinementList || isEmpty(refinementList)) {
		return undefined;
	}
	let finalFilters = {
		brand: undefined,
		size: undefined,
		type: undefined,
		gender: undefined,
		color: undefined,
	};
	for (const [key, value] of Object.entries(refinementList)) {
		const match = filtersMatch[key];

		if (match) {
			finalFilters = {
				...finalFilters,
				[match]:
					match === "color" && value && translationsFiltersColors
						? getColorName(value, locale, translationsFiltersColors)
						: value,
			};
		}
	}
	return finalFilters;
};

export const buildDataLayerPriceRange = ({
	range,
	initialPriceRange,
}: Pick<TAppliedFilters, "initialPriceRange" | "range">) => {
	if (!range) {
		return {};
	}

	const {
		price: { min, max },
	} = range;

	return {
		price: {
			min: min ?? initialPriceRange?.min,
			max: max ?? initialPriceRange?.max,
		},
	};
};

const buildDataLayerFilters = ({
	content,
	locale,
	translationsFiltersColors,
	initialPriceRange,
}: IDataLayerFiltersArgs) => {
	const { refinementList, range } = content;

	if (!refinementList) {
		return;
	}

	return {
		...buildDataLayerOtherFilters(locale, translationsFiltersColors, refinementList),
		...buildDataLayerPriceRange({ range, initialPriceRange }),
	};
};
