import getConfig from "next/config";

import { IOptionSelect } from "@components/Select/Select.component";
import { Dispatch } from "react";
import axios from "axios";
import { setItemsCount, TCartActions } from "@redux/cart/cart.reducer";
import {
	ECartErrorType,
	TCartContextData,
} from "@components/Cart/CartProvider/CartProvider.component";
import { buildDataLayerCartDetails } from "@shared/datalayer/cart-datalayer/cart-datalayer";
import { globalDataLayer, isEvent } from "@shared/datalayer/DataLayer";
import { EDataLayerEventType } from "@shared/datalayer/datalayer.types";
import { IGetRecommendedProductsInfo } from "@shared/recommendation/recommendation.types";
import { TContentTranslation } from "@shared/types/translations.types";
import { ICartData, ICartLine, ICartLineInfoAttribute } from "@shared/types/cart.types";
import { ILines } from "@shared/order-confirmation/order-confirmation.type";
import { IArticleList, IProduct } from "@shared/types/product.types";
import { getModelIdListInfo } from "@shared/recommendation/recommendation.utils";
import { isSize } from "@utils/productSize.utils";
import { IAvailabilityProduct } from "@shared/availability/types";

const { publicRuntimeConfig } = getConfig();

export const getColor = (color: string | undefined) => {
	return color ? `, ${color}` : "";
};

interface IArgs {
	brand: string;
	size?: string;
	label?: string;
	color?: string;
}

export const buildItemInfoLine = ({ brand, size, label, color }: IArgs) => {
	const sizeLine = isSize(size) && `, ${label ?? ""}${size}`;
	const colorText = color ? `, ${color}` : "";
	return `${brand}${colorText}${sizeLine || ""}`;
};

const MAX_QTY = 99;

export const getQuantityToDisplay = (availableQuantity: number): IOptionSelect[] => {
	return [...Array(availableQuantity > MAX_QTY ? MAX_QTY : availableQuantity)].map((_, i) => ({
		id: `${i + 1}`,
		text: `${i + 1}`,
	}));
};

export const retrieveLinesWithQty = ({
	cartLines,
	availability,
}: {
	cartLines?: ICartLine[];
	availability?: (IAvailabilityProduct | undefined)[];
}) =>
	cartLines?.map((line) => ({
		...line,
		availableQuantity:
			availability?.find((product) => product && product.skuId === line.sku)?.quantity ?? 0,
	})) ?? [];

export const extractCartPageInformations = (cartData?: ICartData): TCartContextData | undefined => {
	if (!cartData) {
		return undefined;
	}
	const {
		cartId,
		cartTotal,
		subTotal,
		shippingCost,
		totalTaxAmount,
		cartLines,
		totalLineCount,
		fulfillmentOptionId,
		addresses,
		postalCode,
	} = cartData;

	return {
		orderSummary: {
			cartTotal,
			subTotal,
			shippingCost,
			totalTaxAmount,
		},
		totalLineCount,
		cartId,
		cartLines,
		fulfillmentOptionId,
		addresses,
		postalCode,
	};
};

export const checkForItemErrors = ({
	cartLine,
	quantity,
}: {
	cartLine: ICartLine;
	quantity: number;
	translations?: TContentTranslation;
}) => {
	if (cartLine.availableQuantity === 0) {
		return ECartErrorType.ItemOutOfStock;
	}
	if (cartLine.availableQuantity < quantity) {
		return ECartErrorType.ItemNotEnoughQuantity;
	}
};

export const NO_SIZE_ATTRIBUTES_NAME = "254";

export const extractSizeFromCartLineAttributes = (attribute: ICartLineInfoAttribute) => {
	if (!attribute || attribute.attributeName === NO_SIZE_ATTRIBUTES_NAME) return "";
	return attribute.attributeValue;
};

export const dispatchCartDetails = (cartDetails?: ICartLine[]) => {
	if (!isEvent(EDataLayerEventType.PageReady)) {
		globalDataLayer.customDataLayer?.dispatchData({
			cart: buildDataLayerCartDetails(cartDetails),
		});
	}
};

export const filterCartLine = (itemCode: number, cartLines: ICartLine[]) => {
	if (itemCode && cartLines) {
		return cartLines.filter((cartLine) => cartLine.itemCode === itemCode);
	}
};

export const manageCartItemsCount = async (dispatch: Dispatch<TCartActions>) => {
	try {
		const { data } = await axios.get("/api/cart/line-count");
		if (data?.totalLineCount && typeof data.totalLineCount === "number") {
			dispatch(setItemsCount(data.totalLineCount));
		}
		// eslint-disable-next-line no-empty
	} catch {}
};

export const getCheckoutSuggestions = async ({
	modelId,
	locale,
}: IGetRecommendedProductsInfo): Promise<IProduct[] | undefined> => {
	try {
		if (!modelId) return;
		const { data } = await axios.get("/api/product/get-product-recommendation", {
			params: {
				dsm_codes: String(modelId),
				path: "checkout-suggestions",
				low_price: publicRuntimeConfig.recommendation?.lowPrice || "true",
				nb_products: "20",
				nosize: "true",
			},
		});
		const productList = data?.data?.dsm_codes_list;

		return getModelIdListInfo({ productList, locale });
		// eslint-disable-next-line no-empty
	} catch (error) {}
};

export const findMatchingLineFromArticle = (article: IArticleList, lines: ILines[]) => {
	return lines?.find((line) => line?.item_code === article?.articleId);
};

export const extractCartLineSkus = (cartLines?: ICartLine[]) => {
	return cartLines?.map(({ sku }) => sku)?.toString() ?? "";
};

export const extractCartErrors = (cartLines: ICartLine[]) =>
	cartLines.reduce((acc: { type: ECartErrorType; data: number }[], cartLine: ICartLine) => {
		const errorType = checkForItemErrors({ cartLine, quantity: cartLine.quantity });
		if (errorType) {
			acc.push({ type: errorType, data: cartLine.itemCode });
		}
		return acc;
	}, []);
