import { CookieSerializeOptions } from "cookie";
import { NextApiRequest, NextApiResponse, NextPageContext } from "next";
import nookies from "nookies";

export type TCookieContextRequest =
	| Pick<NextPageContext, "req">
	| {
			req: NextApiRequest;
	  }
	| null
	| undefined;

export type TCookieContextResponse =
	| Pick<NextPageContext, "res">
	| {
			res: NextApiResponse;
	  }
	| null
	| undefined;

export enum ECookieName {
	AlgoliaUserToken = "_ALGOLIA",
	AffiliationCode = "_gtm_ir_irclickid",
	CartId = "user_cart_id",
	CartItemsCount = "user_cart_items_count",
	DisplayModalMergeCarts = "user_display_modal_merge_carts",
	DktConnectAccessToken = "user_dkt_connect_access_token",
	DktConnectRefreshToken = "user_dkt_connect_refresh_token",
	FirstName = "user_given_name",
	GoogleAnalytics = "_ga",
	PostalCodeGeo = "geo_postal_code",
	ProvinceGeo = "geo_province",
	LatitudeGeo = "geo_latitude",
	LongitudeGeo = "geo_longitude",
	SaveForLaterId = "user_save_for_later_id",
	UserRedirect = "user_redirect",
}

const MAX_AGE_COOKIE = 2592000; //1 month
export const MAX_AGE_COOKIE_CART = 864000; //10 days
const MAX_AGE_COOKIE_EXTRA_LARGE = 630720000; //20 years

const DEFAULT_SET_COOKIE_OPTIONS = { maxAge: MAX_AGE_COOKIE, path: "/" };
export const DEFAULT_DELETE_COOKIE_OPTIONS = { path: "/" };
export const PROFILE_SET_COOKIE_OPTIONS = { maxAge: MAX_AGE_COOKIE_EXTRA_LARGE, path: "/" };

export const getCookie = (key: string, appCtx?: TCookieContextRequest) => {
	return nookies.get(appCtx)?.[key];
};

export const setCookie = (
	key: string,
	value: string,
	appCtx?: TCookieContextResponse,
	options: CookieSerializeOptions = DEFAULT_SET_COOKIE_OPTIONS
) => {
	return nookies.set(appCtx, key, value, options);
};

export const isCookieExisting = (key: string, appCtx?: TCookieContextRequest) => {
	const cookie = getCookie(key, appCtx);
	return !!cookie && cookie !== "undefined";
};

export const deleteCookie = (
	key: string,
	appCtx?: TCookieContextResponse,
	options: CookieSerializeOptions = DEFAULT_DELETE_COOKIE_OPTIONS
) => {
	return nookies.destroy(appCtx, key, options);
};

export const deleteCookiesUser = (appCtx?: TCookieContextResponse) => {
	const cookieNameArray = Object.values(ECookieName);
	cookieNameArray.forEach((cookieName) => {
		if (cookieName.startsWith("user_")) {
			return deleteCookie(cookieName, appCtx);
		}
	});
};
