/* eslint-disable @typescript-eslint/no-explicit-any */
import { Context } from "@shared/types/locale.types";
import { useRouter } from "next/router";
import languages from "@config/languages";

export const useChangeLocale = () => {
	const { locale, locales } = useLocale();
	return locales?.find((lang) => lang !== locale) ?? locale;
};

const useLocale = (ctx?: Context) => {
	let locale = languages.defaultLocale;

	if (ctx) {
		locale = ctx.locale || locale;
	} else {
		const { locale: localeRouter } = useRouter();
		locale = localeRouter ?? locale;
	}

	return { locale, ...languages };
};

export default useLocale;
