import { gql } from "graphql-request";

export const componentMappingIdFragment = gql`
	fragment categoryIdMapping on CategoryIdMapping {
		__typename
		identifier
		idType
		mappingCodes
	}
`;
