import { createAsyncThunk, isRejected } from "@reduxjs/toolkit";
import axios from "axios";

import { updateOptionErrors, updateScenario } from "@redux/cart/use-case/use-case.utils";
import type { RootState } from "@redux/reducer";
import { selectScenarioId } from "@redux/scenarios/scenarios.selector";
import { globalDataLayer } from "@shared/datalayer/DataLayer";
import type { ISelectedScenario, IUpdateOptionError } from "@shared/types/cart-content.types";
import { EFulfillmentType } from "@shared/types/delivery.types";
import type { IPickupPointAddress } from "@shared/types/pickupPoints.type";
import getOrderSummary from "./order-summary.use-case";
import { setSelectedFulfillers } from "../cart-content.reducer";
import { EDataLayerEventType } from "@shared/datalayer/datalayer.types";

export interface ISetPickupOption {
	containerId: string;
	deliveryId: string;
	deliverySlotId: string;
	offerId: string;
	shippingId: string;
	scenarioId: string;
	pickupPointId: string;
	pickupPointDeliveryId: string;
	locale: string;
	parcelIndex: number;
	name: string;
	pickupPointAddress?: IPickupPointAddress;
}

const setPickupOption = createAsyncThunk<
	{ scenario: ISelectedScenario; errors: IUpdateOptionError[] },
	ISetPickupOption,
	{ rejectValue: IUpdateOptionError[]; state: RootState }
>("cart/setPickupOption", async (props, { getState, rejectWithValue, dispatch }) => {
	try {
		await axios.post("/api/scenario/select-pick-up-point", props);
		const scenarioId = selectScenarioId(getState());

		if (!scenarioId) {
			return rejectWithValue(
				updateOptionErrors({ getState, props, errorType: EFulfillmentType.StorePickup })
			);
		}

		const result = await dispatch(getOrderSummary({ scenarioId }));

		if (isRejected(result)) {
			return rejectWithValue(
				updateOptionErrors({ getState, props, errorType: EFulfillmentType.StorePickup })
			);
		}

		const {
			cartData: { selectedScenario },
		} = getState();

		globalDataLayer.customDataLayer.dispatchData({
			event: EDataLayerEventType.CheckoutSelectOption,
			checkout: {
				step: "fulfillment",
				fulfillmentOption: "pickup-decathlon-fulfillment",
				option: "select store",
			},
		});

		dispatch(
			setSelectedFulfillers({
				type: EFulfillmentType.StorePickup,
				parcelIndex: props.parcelIndex,
				name: props.name.toLowerCase(),
				id: props.pickupPointId,
			})
		);

		return {
			scenario: updateScenario({
				fulfillmentType: EFulfillmentType.StorePickup,
				option: props,
				selectedScenario,
				pickupPointAddress: props.pickupPointAddress,
			}),
			errors: updateOptionErrors({ getState, props }),
		};
	} catch {
		return rejectWithValue(
			updateOptionErrors({ getState, props, errorType: EFulfillmentType.StorePickup })
		);
	}
});

export default setPickupOption;
