import getConfig from "next/config";
import languages from "@config/languages";
import {
	IArticle,
	IProduct,
	IProductImage,
	IProductProps,
	IAvailabilityArticleSize,
	IProductVideo,
} from "@shared/types/product.types";
import { deleteUrlParameter } from "@utils/url.utils";
import {
	EDataLayerEventType,
	IDataLayerEventPdpViewArticle,
} from "@shared/datalayer/datalayer.types";
import { stockStatus } from "@shared/datalayer/category-datalayer/category-datalayer";
import { findArticle } from "@shared/datalayer/product-datalayer/product-datalayer";
import axios from "axios";
import { getCookie, ECookieName } from "@utils/cookie.utils";
import StatusError from "@utils/StatusError";
import { isSize } from "@utils/productSize.utils";
import { IReviewsData } from "@components/Reviews/Reviews.types";
import { buildMappingCodesList } from "@components/ContentfulComponents/ComponentCarouselItem/carousel-items.utils";
import { IMappingIdCflData } from "@components/ContentfulComponents/ComponentMappingId/type";
import { IAvailabilityProduct } from "@shared/availability/types";

interface ISendTracking {
	locale: string;
	articleId: number;
	queryID: string;
	modelId: number;
	superModel?: string;
}

interface IFindPriceBeforeDiscount {
	modelId?: number;
	mappingId?: IMappingIdCflData;
}

const {
	publicRuntimeConfig: {
		currency,
		algoliaAppId,
		algoliaApiKey,
		algoliaIndexName,
		algoliaInsightApiUrl,
	},
} = getConfig();

const { defaultLocale } = languages;

export const getSizes = (articles: IArticle[]) => {
	return articles?.map((a) => a.attribute.attributeValue);
};

export const formatMetaProductColor = (color?: string) => {
	return color ? ` - ${color}` : "";
};

export const displayPriceWithCurrency = (price?: number, locale = defaultLocale): string => {
	if (!price && price !== 0) {
		return "-";
	}

	return locale === "fr"
		? `${price.toFixed(2).replace(".", ",")}\u00A0${currency}`
		: `${currency}${price.toFixed(2)}`;
};

export const getProductDataLayer = ({
	product,
	locale,
	availabilityArticles,
}: IProductProps): IDataLayerEventPdpViewArticle | null => {
	if (!product) return null;

	const {
		images,
		articles,
		superModel,
		lifeCycle,
		description,
		familyId,
		nature,
		brand,
		currentUrl,
	} = product;

	const dataLayerImages = images?.map((i) => {
		return { url: i.url };
	});

	const dataLayerArticles = articles.map((article) => {
		const articleInfo = findArticle(availabilityArticles, article);

		return {
			canonical: deleteUrlParameter(currentUrl),
			id: article?.articleId,
			EAN: article?.ean,
			supermodel: {
				id: superModel,
				lifestage: lifeCycle,
			},
			model: {
				id: article?.modelId,
				lifestage: article?.lifeCycle,
			},
			name: {
				en: description.productName,
			},
			size: {
				en: article?.attribute?.attributeValue,
				code: article?.attribute?.attributeName,
			},
			lifestage: article.lifeCycle,
			availability: {
				warehouse: articleInfo && stockStatus(articleInfo.quantity),
			},
			price: {
				currency: "CAD",
				original: article?.price?.price?.toFixed(2),
				current: article?.price?.priceReduction?.toFixed(2),
			},
			family: {
				code: familyId,
			},
			nature: {
				id: nature?.natureId,
				[`${locale}`]: nature?.natureName,
			},
			brand,
			description: {
				en: description?.madeFor?.replace(/"/g, '\\"'),
			},
			images: dataLayerImages,
			URL: currentUrl,
		};
	});

	return {
		event: EDataLayerEventType.PageViewProductArticle,
		products: {
			articles: dataLayerArticles,
		},
	};
};

export const getArticleBySelectedSize = (articles: IArticle[], articleId: number) => {
	return articles?.find((article) => article?.articleId === articleId);
};

export const getDefaultArticleEan = (articles: IArticle[]) => {
	return articles?.find((article) => article?.default)?.ean;
};

export const isAllowedNotifyMe = (articles: IArticle[], articleId: number) => {
	const selectedArticle = articles.find((article) => article.articleId === articleId);
	return Boolean(selectedArticle?.stockNotification.notify);
};

export const isArticleAvailable = (
	availabilityArticles: IAvailabilityProduct[],
	selectedArticleSku: string
) => {
	const selectedArticle = availabilityArticles?.find(
		(article) => article.skuId === selectedArticleSku
	);
	return Boolean(selectedArticle && selectedArticle.quantity > 0);
};

const sizeNoSizeLabel = (locale?: string) => {
	return locale === "fr" ? "Sans Taille" : "No Size";
};

export const getAvailabilityArticlesSizes = (
	availabilityArticles: IAvailabilityProduct[],
	articles: IArticle[],
	locale?: string
) => {
	const availabilityBySize: IAvailabilityArticleSize[] = [];
	articles.forEach((article) => {
		const itemMatch = availabilityArticles?.find((item) => item?.skuId === article.sku);
		availabilityBySize.push({
			articleId: article.articleId,
			size: isSize(article.attribute.attributeValue)
				? article.attribute.attributeValue
				: `${sizeNoSizeLabel(locale)}`,
			isNotifyMeAvailable: article.stockNotification.notify,
			isOutOfStock: itemMatch ? itemMatch.quantity === 0 : true,

			sku: article.sku,
		});
	});
	return availabilityBySize;
};

export const sendTracking = async ({
	locale,
	articleId,
	queryID,
	modelId,
	superModel,
}: ISendTracking) => {
	const userToken = getCookie(ECookieName.AlgoliaUserToken);
	try {
		if (!articleId || !queryID || !locale || !modelId || !superModel)
			throw new StatusError(
				422,
				"The article id, super model, model id, query id  or locale is undefined"
			);
		await axios.post(
			algoliaInsightApiUrl,
			{
				events: [
					{
						eventType: "conversion",
						eventName: "Add to cart",
						index: `${algoliaIndexName}_${locale}`,
						userToken,
						objectIDs: [`${superModel}-${modelId}-${articleId}`],
						queryID,
					},
				],
			},
			{
				headers: {
					"x-algolia-api-key": algoliaApiKey,
					"x-algolia-application-id": algoliaAppId,
					"Content-Type": "application/json",
				},
			}
		);
	} catch {
		console.error("cannot send conversion tracking to Algolia");
	}
};

export const formatCurrentColors = (colors?: string[]) => {
	if (!colors?.length) return "";
	return String(colors).replaceAll(",", ", ");
};

export const formatCrossSellingData = (data?: IProduct[]) => {
	return (
		data?.map((item) => ({
			product: {
				articles: [{ articleId: item?.articles?.[0]?.articleId }],
				description: {
					slug: item?.description?.slug,
					slugOther: item?.description?.slugOther,
					productName: item?.description?.productName,
				},
				modelId: item?.modelId,
				superModel: item?.superModel,
				images: [{ url: item?.images?.[0]?.url ?? "" }],
				priceForFront: {
					discountPercentage: item?.priceForFront?.discountPercentage,
					finalPrice: item?.priceForFront?.finalPrice,
					slashedPrice: item?.priceForFront?.slashedPrice,
					showSlash: item?.priceForFront?.showSlash,
				},
				brand: item?.brand,
				review: item?.review,
			},
		})) ?? []
	);
};

export const formatReviewData = (data?: IReviewsData) => {
	if (!data) return {};

	return {
		ratingCount: data?.statistics?.count ?? "",
		ratingAverage: data?.statistics?.averageRating ?? "",
		firstName: data?.reviews?.canada?.[0]?.author ?? data?.reviews?.other?.[0]?.author ?? "",
		note: data?.reviews?.canada?.[0]?.rating ?? data?.reviews?.other?.[0]?.rating ?? "",
	};
};

export const findPriceBeforeDiscount = ({ mappingId, modelId }: IFindPriceBeforeDiscount) => {
	if (!mappingId || !modelId) return;

	const mappingCodeList = buildMappingCodesList(mappingId);
	const mappingCode = mappingCodeList?.find((code) => code?.includes(`${modelId}`));

	if (!mappingCode?.includes(";")) return;

	return Number(mappingCode.replace(`${modelId};`, ""));
};

export const formatModelIdFromUrl = (id: string) => {
	const regexNonDigitAfterId = /^(\d+)[^\d].*$/;
	const productId = regexNonDigitAfterId.exec(id);
	return productId?.[1] ?? id;
};

export const extractArticleSkus = (articles: IArticle[]) => {
	return articles?.map(({ sku }) => sku)?.toString() ?? "";
};

export const isProductVideo = (item: IProductImage | IProductVideo): item is IProductVideo => {
	return "videoId" in item;
};

export const isProductImage = (item: IProductImage | IProductVideo): item is IProductImage => {
	return "id" in item;
};
