import { gql } from "graphql-request";

export const componentTextWithLinkFragment = gql`
	fragment textWithLink on TextWithLink {
		__typename
		sys {
			id
		}
		identifier
		title
		icon
		richTextContent: content {
			json
		}
		link {
			...linkIcon
		}
	}
`;
