/* eslint-disable @typescript-eslint/no-explicit-any */
import { GraphQLClient, ClientError } from "graphql-request";
import { GraphQLRequestContext } from "graphql-request/dist/types";
import getConfig from "next/config";
import useLocale from "./useLocale";
import { useEffect, useState } from "react";

type GraphQLVariables = Record<string, string | number>;

export interface IGraphQLHookOptions<Variables = GraphQLVariables> {
	variables?: Variables;
}

export interface IGraphqlData<T> {
	data: T;
	error?: unknown;
}

const {
	publicRuntimeConfig: {
		contentful: { environment, baseUrl, accessToken, previewAccessToken, spaceId },
	},
} = getConfig();

export const graphQLrequest = async <Variables = GraphQLVariables>(
	query: string,
	variables?: Variables
) => {
	const globalAny: any = globalThis;

	try {
		if (!globalAny.contentFul) {
			globalAny.contentFul = createNewGraphQLClient(accessToken);
		}

		const client = globalAny.contentFul;

		return await client.request(query, variables);
	} catch (error) {
		console.error(error);
		return error;
	}
};

export const createNewGraphQLClient = (token: string) => {
	const endpoint = `${baseUrl}/${spaceId}/environments/${environment}`;
	return new GraphQLClient(endpoint, {
		method: "GET",
		jsonSerializer: {
			parse: JSON.parse,
			stringify: JSON.stringify,
		},
		headers: {
			authorization: `Bearer ${token}`,
		},
	});
};

//TODO: replace all usages of graphQLrequest by graphQLWithPreviewRequest
// will be done in a separate PR.
export const graphQLWithPreviewRequest = async <Variables = GraphQLVariables, Data = any>({
	query,
	variables,
	preview = false,
}: GraphQLRequestContext<Variables> & {
	preview?: boolean;
}): Promise<Data | { error: ClientError }> => {
	const globalAny: any = globalThis;
	let client;
	if (preview) {
		if (!globalAny.contentfulPreview) {
			globalAny.contentfulPreview = createNewGraphQLClient(previewAccessToken);
		}
		client = globalAny.contentfulPreview;
	} else {
		if (!globalAny.contentful) {
			globalAny.contentful = createNewGraphQLClient(accessToken);
		}
		client = globalAny.contentful;
	}

	try {
		return await client.request(query, { ...variables, preview });
	} catch (error) {
		console.error(error);
		return { error: error as ClientError };
	}
};

const useGraphQL = <T, Variables = GraphQLVariables>(
	query: string,
	options: IGraphQLHookOptions<Variables>
): IGraphqlData<T | undefined> => {
	const { locale } = useLocale();
	const variables = { ...options.variables, locale };

	const [data, setData] = useState<T>();

	useEffect(() => {
		(async () => {
			const graphQLData = await graphQLrequest(query, variables);
			graphQLData && setData(graphQLData);
		})();
	}, []);

	return { data };
};

export default useGraphQL;
