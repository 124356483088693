export interface IAvailabilityProduct {
	skuId: string;
	quantity: number;
}

export enum EAvailabilityStatus {
	InStock = "InStock",
	LowStock = "LowStock",
	OutOfStock = "OutOfStock",
}
