// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init } from "@sentry/nextjs";
import { CaptureConsole } from "@sentry/integrations";

const ENABLED = process.env.NEXT_PUBLIC_SENTRY_ENABLED || "false";
const DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SAMPLE_RATE = process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || 1.0;
const levels = process.env.NEXT_PUBLIC_SENTRY_CAPTURE_CONSOLE || "error,warn";

init({
	dsn: DSN || "https://81068fe3f22446a98eec81518dcff74f@o376758.ingest.sentry.io/5893228",
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: +SAMPLE_RATE,
	enabled: ENABLED.toLowerCase() === "true",
	environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || "development",
	integrations: [
		new CaptureConsole({
			levels: levels.replace(/\s/g, "").split(","),
		}), // Send the client's console output
	],
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
});
