import { ReactNode } from "react";
import { IContentBlockCflData } from "@components/ContentfulComponents/BlockContentBlock/type";
import { ITwoColumnsCflData } from "@components/ContentfulComponents/BlockTwoColumnsContent/types";
import { IAssetCflData } from "@components/ContentfulComponents/ComponentAsset/type";
import { IJsonCflData } from "@components/ContentfulComponents/ComponentJson/type";
import { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import { IRichTextCflData } from "@components/ContentfulComponents/ComponentRichText/type";
import { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import { IContentPagesContentBlockCflData } from "@components/ContentfulComponents/ContentPagesContentBlock/type";
import { Block, Inline } from "@contentful/rich-text-types";
import { IHeroBannerCarouselCflData } from "@components/ContentfulComponents/BlockHeroBannerCarousel/type";

export interface ICollection<T> {
	items: T[];
	skip?: number;
	total?: number;
	limit?: number;
}

export interface IContentBlockCollection<T> {
	contentBlockCollection: ICollection<IContentBlockCflData<T>>;
}

export interface ITranslationKeyCollection {
	translationKeyCollection: ICollection<ITranslationKeyCflData>;
}

export interface ILinkCollection {
	linkCollection: ICollection<ILinkCflData>;
}

export enum EComponentTypenames {
	Asset = "ContentTypeAsset", // Asset as component: asset
	AssetWithText = "AssetWithText",
	Banner = "Banner",
	Boolean = "CmpBoolean",
	CarouselItem = "CarouselItem",
	HighlightedText = "HighlightedText",
	Json = "Json",
	Link = "Link",
	MappingId = "CategoryIdMapping",
	Media = "Asset", // native asset from contentful,
	ProductLabel = "ProductLabel",
	QA = "StaticPagesQa",
	RichText = "ContentTypeRichText",
	Reference = "StaticPagesReference",
	TextAssetCtas = "TextAssetAndCtas",
	TextWithLink = "TextWithLink",
	TranslationKey = "TranslationKey",
}

export enum EBlockTypenames {
	ContentBlock = "ContentBlock",
	PagesContentBlock = "StaticPagesBlockOfContent",
	TwoColumns = "StaticPagesTwoColumns",
	Carousel = "BlockCarousel",
	HeroBannerCarousel = "BlockHeroBannerCarousel",
}

export interface IJsonCollection<T = unknown> {
	jsonCollection: ICollection<IJsonCflData<T>>;
}

export interface IContentCollection<T> {
	contentCollection: ICollection<T>;
}

export type TGenericContent<T> =
	| IRichTextCflData
	| ILinkCflData
	| ITranslationKeyCflData
	| IAssetCflData
	| ITwoColumnsCflData
	| IContentBlockCflData<T>
	| IContentPagesContentBlockCflData
	| IHeroBannerCarouselCflData;

export type TRichTextEntryNode = Block | Inline;

export interface IFormatRichText {
	children: ReactNode;
	Tag: keyof JSX.IntrinsicElements;
	className?: string;
}

export interface ISysCfl {
	sys: {
		id: string;
	};
}
