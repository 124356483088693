import { gql } from "graphql-request";

export const componentTranslationKeyFragment = gql`
	fragment translationKey on TranslationKey {
		__typename
		identifier
		sys {
			id
		}
		contentTranslation: content
	}
`;
