import { gql } from "graphql-request";

export const componentAssetFragment = gql`
	fragment asset on ContentTypeAsset {
		__typename
		sys {
			id
		}
		identifier
		asset {
			...media
		}
		assetDesktop {
			...media
		}
		videoUrl
		diffusionPixlId
		videoOptions
		videoTitle
	}
`;

export const assetFragment = gql`
	fragment media on Asset {
		__typename
		width
		url
		height
		description
	}
`;
