import { TContentTranslation } from "@shared/types/translations.types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {};

const { actions, reducer } = createSlice({
	name: "translation",
	initialState,
	reducers: {
		setTranslation(state, action: PayloadAction<TContentTranslation>) {
			return {
				...state,
				...action.payload,
			};
		},
	},
});

export const { setTranslation } = actions;
export default reducer;
