import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { TOrderSummary } from "@shared/types/cart.types";

const getOrderSummary = createAsyncThunk<
	TOrderSummary,
	{ scenarioId?: string },
	{ rejectValue: { error: string } }
>("cart/getOrderSummary", async ({ scenarioId }, { rejectWithValue }) => {
	if (!scenarioId) return rejectWithValue({ error: "scenarioId is missing" });

	try {
		const { data } = await axios.get("/api/cart/order-summary", {
			params: { scenarioId },
		});
		return data;
	} catch {
		return rejectWithValue({ error: "Oops there seems to be an error" });
	}
});

export default getOrderSummary;
