import { IAxiosAuth } from "@shared/axios/axiosAuth.types";
import { User } from "next-auth";

export enum ERefreshError {
	RefreshAccessTokenError = "RefreshAccessTokenError",
}

export interface IJWTToken {
	exp: number;
	sub: string;
	personid: string;
}

export type TValidateDktTokenExpiration = Pick<IAxiosAuth, "req" | "res">;

export interface IJWTRedis {
	sessionId?: string;
	accessToken: string;
	accessTokenExpires: number;
	refreshToken: string;
	user?: User;
	error?: ERefreshError;
}
