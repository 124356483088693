import { createSlice } from "@reduxjs/toolkit";

export interface ILoaderState {
	loaderQueue: number;
	loaderReady: number;
	isLoading: boolean;
	loaderCount: number;
}

const initialState: ILoaderState = {
	loaderQueue: 0,
	loaderReady: 0,
	isLoading: true,
	loaderCount: 0,
};

const { actions, reducer } = createSlice({
	name: "loader",
	initialState,
	reducers: {
		addToQueue(state) {
			return {
				...state,
				loaderQueue: state.loaderQueue + 1,
				isLoading: true,
			};
		},
		toggleReady(state) {
			const { loaderCount, loaderQueue, loaderReady } = state;

			return {
				...state,
				loaderReady: loaderReady + 1,
				isLoading: loaderQueue !== loaderReady + 1,
				loaderCount: loaderCount + 1,
			};
		},
	},
});

export const { addToQueue, toggleReady } = actions;
export default reducer;
