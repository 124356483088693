import type { ISetDeliveryOption } from "@redux/cart/use-case/delivery-option.use-case";
import type { ISetPickupOption } from "@redux/cart/use-case/pickup-option.use-case";
import type { RootState } from "@redux/reducer";
import type { ISelectedScenario } from "@shared/types/cart-content.types";
import { EFulfillmentType } from "@shared/types/delivery.types";
import type { IPickupPointAddress } from "@shared/types/pickupPoints.type";

export const updateScenario = ({
	selectedScenario,
	option,
	fulfillmentType,
	pickupPointAddress,
}: {
	option: ISetDeliveryOption | ISetPickupOption;
	fulfillmentType: EFulfillmentType;
	selectedScenario?: ISelectedScenario;
	pickupPointAddress?: IPickupPointAddress;
}) => {
	const optionContainer = {
		container_id: option.containerId,
		delivery_id: option.deliveryId,
		delivery_slot_id: option.deliverySlotId,
		fulfillment_type: fulfillmentType,
		pickupPointAddress,
	};

	const selectedScenarioOfferId = selectedScenario?.shippings?.[0]?.offer_id;

	if (selectedScenarioOfferId && selectedScenarioOfferId === option.offerId) {
		const containers =
			selectedScenario.shippings[0].containers?.filter(
				({ container_id }) => container_id !== optionContainer.container_id
			) ?? [];

		return {
			...selectedScenario,
			shippings: [
				{
					...selectedScenario.shippings[0],
					containers: [...containers, optionContainer],
				},
			],
		};
	}

	return {
		scenario_id: option.scenarioId,
		shippings: [
			{
				shipping_id: option.shippingId,
				offer_id: option.offerId,
				containers: [optionContainer],
			},
		],
	};
};

export const updateOptionErrors = ({
	props,
	errorType,
	getState,
}: {
	props: ISetPickupOption | ISetDeliveryOption;
	getState: () => RootState;
	errorType?: EFulfillmentType;
}) => {
	const {
		cartData: { errors },
	} = getState();
	const filteredOutContainerErrors = errors.filter(
		({ containerId }) => containerId !== props.containerId
	);

	if (typeof errorType === "undefined") return filteredOutContainerErrors;

	return [
		...filteredOutContainerErrors,
		{
			containerId: props.containerId,
			type: errorType,
		},
	];
};
