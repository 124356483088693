import {
	IContainer,
	IDeliverySlots,
	INonHydratedContainer,
	IParcel,
	IPickupPoint,
} from "@shared/types/containers.types";
import { INonHomologatedScenario } from "@shared/types/scenarios.types";
import { AxiosError, isAxiosError } from "axios";

export const isNonHomologatedProductsError = (
	error: unknown
): error is AxiosError<INonHomologatedScenario> => {
	return isAxiosError(error) && error.response?.status === 422 && "items" in error.response.data;
};

export const isNonHomologated = (
	data: IParcel | INonHomologatedScenario
): data is INonHomologatedScenario => "items" in data;

export const isNonHydratedContainer = (
	container: IContainer | INonHydratedContainer
): container is INonHydratedContainer => "deliveries" in container;

export const isPickupPoint = (pickup: IPickupPoint | IDeliverySlots): pickup is IPickupPoint =>
	"delivery_id" in pickup;
