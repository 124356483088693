import isEmpty from "lodash/isEmpty";
import { EDataLayerEventType } from "./datalayer.types";

/* eslint-disable @typescript-eslint/no-explicit-any */
interface ICustomDataLayer {
	customDataLayer: DataLayer;
	dataLayer: Record<string, any>[];
}

type IDataCustom = ({ event?: string } & Record<string, any>) | undefined;

export const globalDataLayer = globalThis as unknown as ICustomDataLayer;

export const isEvent = (event: string) => {
	if (typeof window !== "undefined") {
		return window.dataLayer?.find((element) => element?.["event"] === event);
	}
};

export const isFieldInDataLayer = (field: string) => {
	if (typeof window !== "undefined") {
		return window.dataLayer?.find((element) => element?.[field as keyof IDataLayer]);
	}
};

export const dispatchDataLayerEvent = (event: EDataLayerEventType) =>
	globalDataLayer.customDataLayer.dispatchData({ event });

export const dispatchDataLayerWebsiteError = (error?: string | Record<string, string>) => {
	const message = isEmpty(error) ? "an error occurred" : JSON.stringify(error);
	globalDataLayer.customDataLayer.dispatchData({
		event: EDataLayerEventType.WebsiteError,
		message,
	});
};

class DataLayer {
	private finishDatalayer = 0;
	private subscriptionQueue = 0;

	constructor() {
		if (!globalDataLayer.dataLayer) globalDataLayer.dataLayer = [];
	}

	subscribe(n = 1) {
		this.subscriptionQueue = this.subscriptionQueue + n;
	}

	subscribeAndDispatchDataAndPageReady(data: any) {
		this.subscribe();
		this.dispatchDataAndPageReady(data);
	}

	dispatchData(data: IDataCustom) {
		this.setData(data);
	}

	dispatchDataAndPageReady(data: any) {
		this.setData(data);
		this.complete();
	}

	dispatchPageReady() {
		this.complete();
	}

	private setData(data: any) {
		window.dataLayer.push(data);
	}

	private complete() {
		this.finishDatalayer = this.finishDatalayer + 1;
		if (this.finishDatalayer === this.subscriptionQueue) {
			this.setData({
				event: EDataLayerEventType.PageReady,
			});
		}
	}
}

export default DataLayer;
