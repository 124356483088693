import { gql } from "graphql-request";
import { booleanFragment } from "@components/ContentfulComponents/ComponentBoolean/fragment";
import { IBooleanCflData } from "@components/ContentfulComponents/ComponentBoolean/type";
import { ICollection } from "@shared/contentful/contentful.types";

export enum EFeatureFlagIdentifier {
	PaymentNotification = "boolean_feature-flag-payment-notification",
	Smartsize = "boolean_feature-flag-smartsize",
	SFL = "boolean_feature-flag-sfl",
	CookieConsent = "boolean_feature-flag-cookie-consent",
	Statsig = "boolean_feature-flag-statsig",
	Klarna = "boolean_feature-flag-klarna",
}

export type TFeatureflagData = {
	cmpBooleanCollection: ICollection<IBooleanCflData>;
};

export interface IFeatureFlagVariables<T> {
	identifier: T;
}

export const featureFlagQuery = gql`
	${booleanFragment}
	query featureFlagQuery($identifier: String, $locale: String) {
		cmpBooleanCollection(where: { identifier: $identifier }, locale: $locale, limit: 1) {
			items {
				...boolean
			}
		}
	}
`;
