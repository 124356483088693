import { RootState } from "@redux/reducer";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICartData, ECartErrors } from "@shared/types/cart.types";
import axios from "axios";

const removeCartItem = createAsyncThunk<
	ICartData,
	{
		cartId: string;
		cartLineIds: string[];
		locale: string;
		itemCodes: number[];
	},
	{ rejectValue: number[]; state: RootState }
>("cart/removeItem", async ({ cartId, cartLineIds, locale, itemCodes }, { rejectWithValue }) => {
	try {
		const { data } = await axios.delete<ICartData>("/api/cart/delete-line", {
			data: {
				cartId,
				cartLineId: cartLineIds,
				locale,
			},
		});

		if (data?.status || data.errorDetails?.errorCode === ECartErrors.Empty) {
			return data;
		}

		return rejectWithValue(itemCodes);
	} catch (error) {
		return rejectWithValue(itemCodes);
	}
});

export default removeCartItem;
