import { RootState } from "@redux/reducer";
import { createSelector } from "reselect";

export const selectMiscStore = (store: RootState) => store.misc;

export const selectMiscIsScrollable = createSelector(selectMiscStore, (misc) => misc.isScrollable);
export const selectMiscReferer = createSelector(selectMiscStore, (misc) => misc.referer);
export const selectMiscPostalCodeUpdateDate = createSelector(
	selectMiscStore,
	(misc) => misc.postalCodeLastUpdate
);

export const selectMiscCookieConsent = createSelector(
	selectMiscStore,
	(misc) => misc.isCookieConsent
);
