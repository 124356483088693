import {
	EFeatureFlagIdentifier,
	IFeatureFlagVariables,
	TFeatureflagData,
	featureFlagQuery,
} from "@shared/featureFlag/featureFlag.query";
import useGraphQL from "./useGraphql";

export const useFeatureFlag = (identifier: EFeatureFlagIdentifier) => {
	const { data } = useGraphQL<TFeatureflagData, IFeatureFlagVariables<EFeatureFlagIdentifier>>(
		featureFlagQuery,
		{
			variables: {
				identifier,
			},
		}
	);
	return data?.cmpBooleanCollection?.items?.[0]?.value;
};
