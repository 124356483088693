import { EComponentTypenames, TGenericContent } from "./contentful.types";
import { TContentTranslation } from "@shared/types/translations.types";
import {
	isLink,
	isContentBlock,
	isRichText,
	isTranslationKey,
	isAsset,
	isJson,
	isTextWithLink,
} from "@shared/contentful/contentful.utils";
import { replaceNonBreakingSymbol } from "@shared/text/text.utils";
import { IJsonCflData } from "@components/ContentfulComponents/ComponentJson/type";
import { IQaCflData } from "@components/ContentfulComponents/ComponentQA/type";

export type TTranslatableContent =
	| TGenericContent<TGenericContent<unknown>>
	| TGenericContent<unknown>
	| IJsonCflData<unknown>
	| IQaCflData;

export const getContentTranslations = <T>(
	contents: TTranslatableContent[] = [],
	contentsTranslations = {}
): TContentTranslation<T> => {
	contents.forEach((content) => {
		if (!content?.identifier) return;
		else if (isContentBlock(content)) {
			contentsTranslations = {
				...contentsTranslations,
				...getContentTranslations(content.contentCollection.items as TGenericContent<unknown>[]),
			};
		} else if (isLink(content)) {
			contentsTranslations = {
				...contentsTranslations,
				[content.identifier]: {
					...content,
					label: replaceNonBreakingSymbol(content.label),
				},
			};
		} else if (isRichText(content)) {
			contentsTranslations = {
				...contentsTranslations,
				[content.identifier]: {
					...content,
				},
			};
		} else if (isTranslationKey(content)) {
			contentsTranslations = {
				...contentsTranslations,
				[content.identifier]: replaceNonBreakingSymbol(content.contentTranslation),
			};
		} else if (isTextWithLink(content)) {
			contentsTranslations = {
				...contentsTranslations,
				[content.identifier]: {
					...content,
					title: replaceNonBreakingSymbol(content.title),
				},
			};
		} else if (isAsset(content)) {
			contentsTranslations = {
				...contentsTranslations,
				[content.identifier]: content.asset,
			};
		} else if (isJson(content)) {
			contentsTranslations = {
				...contentsTranslations,
				[content.identifier]: content.jsonContent,
			};
		} else {
			console.warn(`Unhandled "${typeof content}" content type in`, content);
		}
	});
	return contentsTranslations;
};

export const selectTranslation = <T>({
	key,
	type = EComponentTypenames.TranslationKey,
	translations,
}: {
	key: string;
	type?: EComponentTypenames;
	translations?: TContentTranslation;
}): T => {
	const translationValue = translations?.[key];
	const keyFallback = `[${key}]`;

	if (type === EComponentTypenames.Link) {
		const linkFallback = {
			__typename: EComponentTypenames.Link,
			identifier: "",
			label: keyFallback,
			sys: { id: "" },
			url: "",
		};
		return (translationValue ?? linkFallback) as T;
	}

	return (translationValue || keyFallback) as T;
};
