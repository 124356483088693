import { otherLocale } from "@config/languages";
import getConfig from "next/config";

export enum EVendor {
	Algolia = "algolia-kd34yCxA",
}

const {
	publicRuntimeConfig: {
		didomi: { apiKey },
	},
} = getConfig();

export const getLanguageCode = (locale: string) => {
	return locale === otherLocale ? "fr-CA" : locale;
};

export const didomiConfig = (locale: string) => ({
	app: {
		apiKey,
	},
	languages: {
		enabled: [getLanguageCode(locale)],
		default: getLanguageCode(locale),
	},
});
