import { EAddressType } from "@components/Checkout/Delivery/How/Address/Address.type";
import { IPriceData, ISticker } from "./product.types";

export interface ICartLineInfoAttribute {
	attributeName: string;
	attributeValue: string;
}
export interface ICartArticleInfo {
	attribute: ICartLineInfoAttribute;
	itemCode: number;
	itemName: string;
	priceForFront: IPriceData;
}

interface ICartProductCategory {
	categoryId: number;
	name: string;
	slug: string;
}

interface ICartProductReview {
	averageRating: number;
	count: number;
}

export interface ICartLine {
	cartLineId: string;
	itemCode: number;
	sku: string;
	modelCode: number;
	quantity: number;
	availableQuantity: number;
	lineType: string;
	unitPrice: number;
	taxRate: number;
	totalPriceWithTax: number;
	imageUrl: string;
	sticker: ISticker | null;
	review: ICartProductReview;
	saleable: boolean;
	productSlug: string;
	selectedArticleInfo: ICartArticleInfo;
	productCategory: ICartProductCategory;
	brand: string;
	lifeCycle: number;
	channel: string;
	superModel: string;
	isStrippedDownProductCard?: boolean;
}

interface ICartAddress {
	addressType: EAddressType;
	id: string;
}

interface ICartErrorItems {
	availableQuantity: number;
	itemId: string;
	itemName: string;
	requestedQuantity: number;
}

interface ICartDataError {
	errorCode: string;
	errorMessage: string;
	items?: ICartErrorItems[];
}

//Temporary interface created until names are changed in the backend
export interface ICartLineElement {
	cartLineIde: string;
	itemCode: number;
	itemSku: string;
	modelCode: number;
	quantityAdded: number;
	quantityAvailable: number;
}

export interface ICartData {
	cartId: string;
	cartLines: ICartLine[];
	status: boolean;
	errorDetails: ICartDataError | null;
	totalLineCount: number;
	fulfillerId: string | null;
	fulfillmentOptionId: string | null;
	addresses: ICartAddress[] | null;
	totalTaxAmount?: number;
	postalCode: string;
	shippingCost?: number | null;
	subTotal?: number;
	cartTotal?: number;
}

export interface ICartItemsScenario {
	quantity: number;
	sku_id: string;
	option_id: number;
	fulfiller_id: string;
	price: { price_id: number; unit_price: number };
}

export interface ICartInfo {
	cartId: string;
	addresses: ICartAddress[] | null;
	cartLines: ICartLine[];
	fulfillmentOptionId: string | null;
	postalCode: string;
	totalLineCount: number;
	orderSummary: TOrderSummary;
}

export type TOrderSummary = Pick<
	ICartData,
	"cartTotal" | "subTotal" | "shippingCost" | "totalTaxAmount"
>;

// TODO: waiting for all error codes
export enum ECartErrors {
	Empty = "C005",
}

export interface IOneQuotationResponse {
	total_without_taxes: number;
	total_shipping: number;
	total_taxes: number;
	total_with_taxes: number;
}
