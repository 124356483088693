class StatusError extends Error {
	private status: number;
	private info: string;
	constructor(status: number, message: string) {
		super(message);
		this.status = status;
		this.info = message;
	}
}

export default StatusError;
