import { gql } from "graphql-request";

export const componentCarouselItemFragment = gql`
	fragment carouselItem on CarouselItem {
		__typename
		sys {
			id
		}
		identifier
		asset {
			...asset
		}
		mappingId {
			...categoryIdMapping
		}
		itemLink {
			...link
		}
		itemTitle
		oldEntry
	}
`;
