import { TCategoryCarousel } from "@components/Category/category.types";
import { IProductCarousel } from "@shared/types/product.types";
import { IMappingIdCflData } from "../ComponentMappingId/type";

interface IFindCarouselInfoById {
	mappingId?: IMappingIdCflData;
	carouselInfo: TCategoryCarousel[] | IProductCarousel[];
	isCategory?: boolean;
}

export const buildMappingCodesList = (mappingId?: IMappingIdCflData) => {
	return mappingId?.mappingCodes?.split("\n");
};

export const findCarouselItemInfoById = ({
	mappingId,
	carouselInfo,
	isCategory = false,
}: IFindCarouselInfoById) => {
	if (!mappingId || !carouselInfo?.length) return;
	const mappingListItem = buildMappingCodesList(mappingId)?.[0];
	const mappingCode = mappingListItem && Number(mappingListItem);
	return isCategory
		? (carouselInfo as TCategoryCarousel[]).find((item) => item?.categoryId === mappingCode)
		: (carouselInfo as IProductCarousel[]).find((item) => item?.modelId === mappingCode);
};
