import { createWrapper } from "next-redux-wrapper";
import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "@redux/reducer";

export const store = () =>
	configureStore({
		reducer: rootReducer,
		devTools: process.env.NODE_ENV !== "production",
	});

export const wrapper = createWrapper(store);

type StoreType = ReturnType<typeof store>;
export type AppDispatch = StoreType["dispatch"];
