import { gql } from "graphql-request";

export const pageLayoutFragment = gql`
	fragment pageCategory on PageCategory {
		identifier
		title
		layoutType
		canonical
		mappingId {
			...categoryIdMapping
		}
		contentCollection(limit: 18) {
			items {
				__typename
				... on ContentBlock {
					identifier
					contentCollection {
						items {
							__typename
							...carouselItem
						}
					}
				}
			}
		}
		topSeoText {
			json
		}
		bottomSeoText {
			json
		}
		bottomSeoContentBlocksCollection {
			__typename
			items {
				...textWithLink
			}
		}
		bottomSeoQaCollection {
			__typename
			items {
				__typename
				...richText
				...qa
			}
		}
	}
`;
