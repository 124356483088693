import { RootState } from "@redux/reducer";
import { createSelector } from "@reduxjs/toolkit";
import { ICartInfo, ICartLine } from "@shared/types/cart.types";

export type IProductInfo = Pick<
	ICartLine,
	| "brand"
	| "imageUrl"
	| "itemCode"
	| "modelCode"
	| "productCategory"
	| "productSlug"
	| "quantity"
	| "selectedArticleInfo"
	| "sku"
>;

export const selectCartContentStore = (store: RootState) => store.cartData;

export const selectSelectedScenario = createSelector(
	selectCartContentStore,
	({ selectedScenario }) => selectedScenario
);

export const selectCartProductsInfo = createSelector(selectCartContentStore, ({ data }) => {
	const productInfos =
		data?.cartLines?.map((cartLine): IProductInfo => {
			const {
				itemCode,
				quantity,
				imageUrl,
				productSlug,
				selectedArticleInfo,
				productCategory,
				brand,
				sku,
				modelCode,
			} = cartLine || {};

			return {
				itemCode,
				quantity,
				imageUrl,
				productSlug,
				selectedArticleInfo,
				productCategory,
				brand,
				sku,
				modelCode,
			};
		}) || [];

	return productInfos;
});

export const selectCartInfo = createSelector(
	selectCartContentStore,
	({
		data: {
			totalLineCount,
			cartId,
			addresses,
			cartLines,
			fulfillmentOptionId,
			postalCode,
			cartTotal,
			subTotal,
			shippingCost,
			totalTaxAmount,
		},
	}): ICartInfo => ({
		orderSummary: {
			cartTotal,
			subTotal,
			shippingCost,
			totalTaxAmount,
		},
		cartId,
		addresses,
		cartLines,
		fulfillmentOptionId,
		postalCode,
		totalLineCount,
	})
);

export const selectCartErrors = createSelector(
	selectCartContentStore,
	({ cartErrors }) => cartErrors
);

export const selectOrderSummary = createSelector(selectCartContentStore, ({ orderSummary }) => ({
	shippingCost: orderSummary?.shippingCost,
	cartTotal: orderSummary?.cartTotal,
	subTotal: orderSummary?.subTotal,
	totalTaxAmount: orderSummary?.totalTaxAmount,
}));

export const selectErrors = createSelector(selectCartContentStore, ({ errors }) => errors);

export const selectScenarioContainers = createSelector(
	selectSelectedScenario,
	(selectedScenario) => selectedScenario?.shippings?.[0]?.containers
);

export const selectSelectedFulfiller = createSelector(
	selectCartContentStore,
	({ selectedFulfillers }) => selectedFulfillers
);
