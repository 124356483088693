import { useSession } from "next-auth/react";
import {
	createContext,
	Dispatch,
	ReactNode,
	useEffect,
	useState,
	useMemo,
	useReducer,
	SetStateAction,
} from "react";
import { useDispatch } from "react-redux";

import { cartContextReducer, ICartContextActions, initCartContext } from "./cart-context.reducer";
import { refreshCartProvider } from "./cart-provider.utils";
import { dispatchCartDetails } from "@shared/cart/cart.utils";

import { ICartData, TOrderSummary, ICartLine } from "@shared/types/cart.types";
import useLocale from "@hooks/useLocale";
import { IProductWithAvailability } from "@shared/types/product.types";

export type TCartContextData = Pick<
	ICartData,
	"cartLines" | "cartId" | "totalLineCount" | "fulfillmentOptionId" | "addresses" | "postalCode"
> & {
	orderSummary: TOrderSummary;
};

export enum ECartErrorType {
	ClearError,
	EditCartLineQuantityError,
	ItemNonHomologatedError,
	ItemNotEnoughQuantity,
	ItemOutOfStock,
	UpdateCartPostalCodeError,
	ViewCartError,
	ErrorMessageBadReturnApi,
}

export interface ICartError {
	type: ECartErrorType;
	data?: number | number[];
}

export interface ILatestItemSavedForLater {
	item: ICartLine;
	index: number;
}

export interface ICartContextData {
	cartInfo: TCartContextData;
	isLoading: boolean;
	loaderCount: number;
	recommendationData: IProductWithAvailability[];
	errors?: ICartError[];
}

interface ICartContext {
	state: ICartContextData;
	dispatch: Dispatch<ICartContextActions>;
	requireRefresh: () => void;
	latestItemSavedForLater?: ILatestItemSavedForLater;
	setLatestItemSavedForLater: Dispatch<SetStateAction<ILatestItemSavedForLater | undefined>>;
}

interface IProps {
	children: ReactNode;
	value?: ICartContext;
	cartInitialState?: ICartContextData;
}

export const CartContext = createContext<ICartContext>(initCartContext);

const CartProvider = ({ children, value, cartInitialState }: IProps) => {
	const [state, dispatchContext] = useReducer(
		cartContextReducer,
		cartInitialState ?? initCartContext.state
	);
	const dispatch = useDispatch();
	const { data: session } = useSession();
	const { locale } = useLocale();
	const [needRefresh, setNeedRefresh] = useState(!cartInitialState);
	const [latestItemSavedForLater, setLatestItemSavedForLater] =
		useState<ILatestItemSavedForLater>();

	const requireRefresh = () => {
		setNeedRefresh(true);
	};

	const contextValue = useMemo(() => {
		return {
			state,
			dispatch: dispatchContext,
			requireRefresh,
			latestItemSavedForLater,
			setLatestItemSavedForLater,
		};
	}, [state, dispatchContext, requireRefresh, latestItemSavedForLater]);

	useEffect(() => {
		dispatchCartDetails(state.cartInfo?.cartLines);
	}, []);

	useEffect(() => {
		if (needRefresh && !state.isLoading) {
			refreshCartProvider({
				locale,
				dispatch,
				dispatchContext,
				setNeedRefresh,
			});
		}
	}, [session, needRefresh]);

	return <CartContext.Provider value={value ?? contextValue}>{children}</CartContext.Provider>;
};

export default CartProvider;
