import { gql } from "graphql-request";

import { componentRichTextFragment } from "@components/ContentfulComponents/ComponentRichText/fragment";
import { componentTranslationKeyFragment } from "@components/ContentfulComponents/ComponentTranslationKey/fragment";
import { componentCarouselItemFragment } from "@components/ContentfulComponents/ComponentCarouselItem/fragment";
import { componentLinkWithIconFragment } from "@components/ContentfulComponents/ComponentLink/fragment";
import {
	assetFragment,
	componentAssetFragment,
} from "@components/ContentfulComponents/ComponentAsset/fragment";
import { componentMappingIdFragment } from "@components/ContentfulComponents/ComponentMappingId/fragment";
import { pageLayoutFragment } from "@components/ContentfulComponents/PageLayout/fragment";
import { ICollection } from "@shared/contentful/contentful.types";
import { jsonFragment } from "@components/ContentfulComponents/ComponentJson/fragment";
import { componentTextWithLinkFragment } from "@components/ContentfulComponents/ComponentTextWithLink/fragment";
import { componentQaFragment } from "@components/ContentfulComponents/ComponentQA/fragment";
import { IPageLayoutCflData } from "@components/ContentfulComponents/PageLayout/type";

export interface ICategoryPageData {
	pageCategoryCollection: ICollection<IPageLayoutCflData>;
}

export const categoryPageQuery = gql`
	${assetFragment}
	${componentAssetFragment}
	${componentTextWithLinkFragment}
	${componentMappingIdFragment}
	${componentCarouselItemFragment}
	${componentLinkWithIconFragment}
	${componentQaFragment}
	${componentRichTextFragment}
	${pageLayoutFragment}
	query categoryPageQuery($categoryId: String, $locale: String) {
		pageCategoryCollection(
			where: { mappingId: { mappingCodes_contains: $categoryId } }
			locale: $locale
			limit: 1
		) {
			items {
				...pageCategory
			}
		}
	}
`;

export enum ECategoryIdentifiers {
	TextShowMoreFilters = "text_show-more-filters",
	TextShowLessFilters = "text_show-less-filters",
	AriaFilterPanelName = "aria_filter-panel-name",
	AriaCloseFilterPanel = "aria_close-filter-panel",
	TranslationSortAndFilters = "translation_sort-and-filters",
	TranslationMoreFilters = "translation_more-filters",
	TextPanelTitle = "text_panel-title",
	TranslationProductCardColourAvailable = "translation_product-card-colour-available",
	TranslationProductCardColoursAvailable = "translation_product-card-colours-available",
	BlockCategorySearchAlgoliaTranslations = "block_category-search-algolia-translations",
	TranslationA11yExpand = "translation_a11y-expand",
	TranslationA11yCollapse = "translation_a11y-collapse",
	TranslationA11yRemoveFilter = "translation_a11y-remove-filter",
}

export enum EFiltersSortingPanelFooterIdentifiers {
	TranslationClearAll = "translation_clear-all",
	TextApplyFiltersButton = "text_apply-filters-button",
}

export enum ECategoryFiltersIdentifiers {
	JsonCategoryFilterTranslations = "json_category-filter-translations",
	JsonCategoryFilterColorTranslations = "json_category-filter-color-translations",
}

export const categorySearchPageQuery = gql`
	${componentRichTextFragment}
	${componentTranslationKeyFragment}
	${jsonFragment}
	query defaultCategoryContent(
		$locale: String
		$defaultCategorySearchPageId: String
		$filtersId: String
		$filtersColorsId: String
	) {
		defaultCategorySearchPage: contentBlockCollection(
			where: { identifier: $defaultCategorySearchPageId }
			locale: $locale
			limit: 1
		) {
			items {
				contentCollection(limit: 15) {
					items {
						__typename
						...translationKey
						...richText
						... on ContentBlock {
							identifier
							contentCollection {
								items {
									...translationKey
								}
							}
						}
					}
				}
			}
		}
		filtersColors: jsonCollection(where: { identifier: $filtersColorsId }) {
			items {
				...json
			}
		}
		filters: jsonCollection(where: { identifier: $filtersId }) {
			items {
				...json
			}
		}
	}
`;

export enum ERankedCategoryIdentifiers {
	CategoryNoIndexNoFollow = "seo-category-mapping_no-index-no-follow",
	CategoryNoIndexFollow = "seo-category-mapping_no-index-follow",
	CategoryIndexNoFollow = "seo-category-mapping_index-no-follow",
}

export const categoryIdsNotRankedQuery = gql`
	${componentMappingIdFragment}
	query categoryIdsNotRankedQuery($identifier: String, $locale: String) {
		categoryIdMappingCollection(where: { identifier: $identifier }, locale: $locale, limit: 1) {
			items {
				...categoryIdMapping
			}
		}
	}
`;

export const robotsCategoryQuery = gql`
	${componentMappingIdFragment}
	query robotsCategoryQuery($categoryId: String, $idType: String, $locale: String) {
		categoryIdMappingCollection(
			where: { idType: $idType, mappingCodes_contains: $categoryId }
			locale: $locale
			limit: 1
		) {
			items {
				...categoryIdMapping
			}
		}
	}
`;
