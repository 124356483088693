import { ReactNode } from "react";
import { StatsigProvider } from "statsig-react";
import getConfig from "next/config";
import { globalDataLayer } from "@shared/datalayer/DataLayer";
import { IStatsigUserProps } from "@shared/statsig/statsig.types";
import { useFeatureFlag } from "@hooks/useFeatureFlag";
import { EFeatureFlagIdentifier } from "@shared/featureFlag/featureFlag.query";

export interface IStatsigProps {
	children: ReactNode;
	user?: IStatsigUserProps;
}

const {
	publicRuntimeConfig: {
		statsig: { clientId, environment },
	},
} = getConfig();

const Statsig = ({ user, children }: IStatsigProps) => {
	const isStatsigEnabled = useFeatureFlag(EFeatureFlagIdentifier.Statsig);

	if (!clientId || !environment || !isStatsigEnabled) return children;

	return (
		<StatsigProvider
			sdkKey={clientId}
			waitForInitialization
			options={{
				environment: { tier: environment },
				disableAutoMetricsLogging: true,
				initCompletionCallback: () => {
					globalDataLayer.customDataLayer.dispatchData({
						event: "statsig.intialized",
					});
				},
			}}
			user={user ?? {}}
		>
			{children}
		</StatsigProvider>
	);
};

export default Statsig;
