export enum EProductSizeType {
	NoSize,
	OneSize,
	ManySizes,
}

export const NO_SIZE = "NO SIZE";

const noSizeRegex = /(0000)|((\.(\s)*\.?)+)$|(sans taille?)|(no size?)/i;

export const getProductSizesType = (sizes: string[] | undefined) => {
	if (!sizes?.length) return EProductSizeType.NoSize;
	if (sizes.length === 1) {
		if (isSize(sizes[0])) {
			return EProductSizeType.OneSize;
		} else {
			return EProductSizeType.NoSize;
		}
	}
	return EProductSizeType.ManySizes;
};

export const isSize = (size: string | undefined) => {
	return Boolean(size && !noSizeRegex.exec(size));
};

export const getProductSizeCols = (maxSizeLength: number) => {
	if (maxSizeLength < 5) {
		return "smallSizeCols";
	} else if (maxSizeLength >= 5 && maxSizeLength < 11) {
		return "mediumSizeCols";
	} else {
		return "largeSizeCols";
	}
};
