import axios from "axios";
import { IAddressInputData } from "@components/Checkout/Delivery/How/Address/Address.type";

export const MAX_ADDRESS_LIST = 24;

export const getDefaultAddress = async (): Promise<IAddressInputData | undefined> => {
	try {
		const { data } = await axios.get("/api/addresses");
		if (data) {
			return data.find((address: IAddressInputData) => address?.is_default);
		} else {
			return undefined;
		}
	} catch (error) {
		return undefined;
	}
};

interface IGetAddresses {
	defaultAddress?: IAddressInputData;
	alternativeAddresses: IAddressInputData[];
}

export const getUrlSaveAddresses = (address_id?: string) => {
	return address_id ? "/api/addresses/edit-address" : "/api/addresses/create-address";
};

export const getAddresses = async (): Promise<IGetAddresses> => {
	try {
		const { data } = await axios.get("/api/addresses");
		if (data) {
			const defaultAddress = data.filter((address: IAddressInputData) => address.is_default);
			const alternativeAddresses = data.filter((address: IAddressInputData) => !address.is_default);
			return {
				defaultAddress: defaultAddress?.[0],
				alternativeAddresses,
			};
		} else {
			return { defaultAddress: undefined, alternativeAddresses: [] };
		}
	} catch (error) {
		return { defaultAddress: undefined, alternativeAddresses: [] };
	}
};

export const deleteAddress = async (addressId: string) => {
	if (!addressId) {
		return false;
	}
	try {
		await axios.delete("/api/addresses/delete", {
			params: { address_id: addressId },
		});
		return true;
	} catch (error) {
		return false;
	}
};
