import { signIn, signOut, useSession } from "next-auth/react";
import { useEffect } from "react";

import { ERefreshError } from "@shared/auth/token.types";
import { isRedirectSignIn } from "@utils/url.utils";
import { deleteCookie, isCookieExisting } from "@utils/cookie.utils";
import { refreshTokenErrorCookie } from "@config/token";

const SignInError = () => {
	const { data: session } = useSession();

	useEffect(() => {
		if (isCookieExisting(refreshTokenErrorCookie)) {
			(async () => {
				deleteCookie(refreshTokenErrorCookie);
				await signOut();
			})();
		}
	}, []);

	useEffect(() => {
		if (
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(session as any)?.error === ERefreshError.RefreshAccessTokenError &&
			isRedirectSignIn(window.location.pathname)
		) {
			signIn("auth0");
		}
	}, [session]);

	return null;
};

export default SignInError;
