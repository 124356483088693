import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IFilterState {
	displayedFiltersAnchor: string[];
}

const initialState: IFilterState = {
	displayedFiltersAnchor: [],
};

const { actions, reducer } = createSlice({
	name: "filter",
	initialState,
	reducers: {
		addDisplayedFilterAnchor(state, action: PayloadAction<string>) {
			return {
				...state,
				displayedFiltersAnchor: [...state.displayedFiltersAnchor, action.payload],
			};
		},
		removeDisplayedFilterAnchor(state, action: PayloadAction<string>) {
			const displayedFiltersAnchor = state.displayedFiltersAnchor.filter((stateFilter) => {
				return stateFilter !== action.payload;
			});

			return {
				...state,
				displayedFiltersAnchor,
			};
		},
	},
});

export const { addDisplayedFilterAnchor, removeDisplayedFilterAnchor } = actions;
export default reducer;
