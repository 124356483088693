import { gql } from "graphql-request";

export const componentQaFragment = gql`
	fragment qa on StaticPagesQa {
		__typename
		sys {
			id
		}
		identifier
		question
		openLinksInANewTab
		answer {
			json
		}
	}
`;
