import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICartData, TOrderSummary } from "@shared/types/cart.types";
import setDeliveryOption from "./use-case/delivery-option.use-case";
import { HYDRATE } from "next-redux-wrapper";
import { THydrateAction } from "@redux/reducer";
import setPickupOption from "@redux/cart/use-case/pickup-option.use-case";
import { ICartDataState, ISelectedFulfiller } from "@shared/types/cart-content.types";
import getOrderSummary from "./use-case/order-summary.use-case";
import { ICartError } from "@components/Cart/CartProvider/CartProvider.component";
import getCart from "@redux/cart/use-case/cart.use-case";
import removeCartItem from "./use-case/removeCartItem.use-case";
import getScenario from "@redux/scenarios/use-case/scenario.use-case";

export const initialState: ICartDataState = {
	data: {},
	loading: false,
	errors: [],
	selectedFulfillers: [{ type: "default" }],
};

const { actions, reducer } = createSlice({
	name: "cart",
	initialState,
	reducers: {
		setCart: (state, action: PayloadAction<ICartData>) => {
			state.data = action.payload;
		},
		setCartErrors: (state, action: PayloadAction<ICartError[]>) => {
			state.cartErrors = action.payload;
		},
		setSelectedFulfillers: (state, action: PayloadAction<ISelectedFulfiller>) => {
			const index = action.payload.parcelIndex ?? 0;
			state.selectedFulfillers[index] = action.payload;
		},
		setOrderSummary: (state, action: PayloadAction<TOrderSummary>) => {
			state.orderSummary = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getScenario.fulfilled, (state) => {
				state.selectedFulfillers = initialState.selectedFulfillers;
				state.selectedScenario = initialState.selectedScenario;
			})
			.addCase(setDeliveryOption.rejected, (state, { payload }) => {
				state.loading = false;
				state.errors = payload ?? [];
			})
			.addCase(setDeliveryOption.pending, (state) => {
				state.loading = true;
			})
			.addCase(setDeliveryOption.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.selectedScenario = payload.scenario;
				state.errors = payload.errors;
			})
			.addCase(setPickupOption.pending, (state) => {
				state.loading = true;
			})
			.addCase(setPickupOption.rejected, (state, { payload }) => {
				state.loading = false;
				state.errors = payload ?? [];
			})
			.addCase(setPickupOption.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.selectedScenario = payload.scenario;
				state.errors = payload.errors;
			})
			.addCase(getOrderSummary.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.orderSummary = payload;
			})
			.addCase(getOrderSummary.pending, (state) => {
				state.loading = true;
			})
			.addCase(getOrderSummary.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getCart.fulfilled, (state, { payload }) => {
				state.loading = payload?.loading ?? false;
				state.data = payload?.data ?? {};
				state.cartErrors = payload?.cartErrors;
			})
			.addCase(getCart.pending, (state) => {
				state.loading = true;
			})
			.addCase(getCart.rejected, (state, { payload }) => {
				state.loading = false;
				state.cartErrors = payload;
			})
			.addCase(removeCartItem.pending, (state) => {
				state.loading = true;
			})
			.addCase(removeCartItem.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.data = payload;
			})
			.addCase(removeCartItem.rejected, (state) => {
				state.loading = false;
			})
			.addCase(HYDRATE, (state, { payload }: THydrateAction) => {
				return { ...state, ...payload.cartData };
			});
	},
});

export const { setCart, setSelectedFulfillers, setCartErrors, setOrderSummary } = actions;

export default reducer;
