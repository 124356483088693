import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUserGeolocation, setUserGeolocationCookies } from "@utils/geolocation.utils";

export interface IMiscState {
	isScrollable: boolean;
	referer?: string;
	postalCodeLastUpdate?: string;
	isCookieConsent?: boolean;
}

const initialState: IMiscState = {
	isScrollable: true,
};

export const updateUserGeolocation = createAsyncThunk(
	"misc/updateUserGeolocation",
	async (postalCode: string, { dispatch, rejectWithValue }) => {
		try {
			const userGeolocation = await getUserGeolocation(postalCode);
			if (!userGeolocation) return rejectWithValue({ error: true });
			setUserGeolocationCookies(userGeolocation);
			dispatch(setPostalCodeLastUpdate(new Date().toISOString()));
			return { userGeolocation };
		} catch (error) {
			return rejectWithValue({ error: true });
		}
	}
);

const { actions, reducer } = createSlice({
	name: "misc",
	initialState,
	reducers: {
		toggleScroll(state, action: PayloadAction<boolean>) {
			return {
				...state,
				isScrollable: action.payload,
			};
		},
		setReferer(state, action: PayloadAction<string>) {
			return {
				...state,
				referer: action.payload,
			};
		},
		setPostalCodeLastUpdate(state, action: PayloadAction<string>) {
			return {
				...state,
				postalCodeLastUpdate: action.payload,
			};
		},
		setCookieConsent(state, action: PayloadAction<boolean>) {
			return {
				...state,
				isCookieConsent: action.payload,
			};
		},
	},
});

export const { toggleScroll, setReferer, setPostalCodeLastUpdate, setCookieConsent } = actions;
export default reducer;
