import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import { setItemsCount, TCartActions } from "@redux/cart/cart.reducer";

import { ECartErrorType } from "./CartProvider.component";
import { ECartContextActionsType, ICartContextActions } from "./cart-context.reducer";

import { TCheckoutActions, setQueue, setReady } from "@redux/checkout/checkout.reducer";
import { ICartData } from "@shared/types/cart.types";
import { extractCartPageInformations } from "@shared/cart/cart.utils";
import { setCartIdInCookies } from "@shared/cart/cookies.utils";

interface IDispatch {
	dispatch: Dispatch<TCartActions | TCheckoutActions>;
	dispatchContext: Dispatch<ICartContextActions>;
	setNeedRefresh?: Dispatch<SetStateAction<boolean>>;
}

export interface IRefreshCartProvider extends IDispatch {
	locale: string;
}

export const refreshCartProvider = ({
	locale,
	dispatch,
	dispatchContext,
	setNeedRefresh,
}: IRefreshCartProvider) => {
	dispatchContext({ type: ECartContextActionsType.SetLoading });
	refreshViewCart(locale, { dispatch, dispatchContext, setNeedRefresh });
};

export const refreshViewCart = (
	locale: string,
	{ dispatch, dispatchContext, setNeedRefresh }: IDispatch
) => {
	(async () => {
		dispatch(setQueue());
		try {
			const { data: cartData } = await axios.get<ICartData>("/api/cart/view-cart", {
				params: { locale },
				headers: {
					"Cache-Control": "no-cache",
				},
			});

			if (cartData) {
				manageCartPageInformations(cartData, {
					dispatch,
					dispatchContext,
				});
			} else {
				dispatchContext({
					type: ECartContextActionsType.SetError,
					payload: { type: ECartErrorType.ViewCartError },
				});
			}
		} catch (error) {
			dispatchContext({ type: ECartContextActionsType.ClearError });
			console.error("An error occured retrieving cart summary", error);
		} finally {
			dispatch(setReady());
			setNeedRefresh?.(false);
		}
	})();
};

export const manageCartPageInformations = (
	cartData: ICartData,
	{ dispatch, dispatchContext }: IDispatch
) => {
	const cartPayload = extractCartPageInformations(cartData);
	if (cartPayload) {
		const { cartId, totalLineCount } = cartPayload;
		setCartIdInCookies(cartId);
		dispatch(setItemsCount(totalLineCount));
		dispatchContext({
			type: ECartContextActionsType.UpdateCart,
			payload: cartPayload,
		});
	}
};
