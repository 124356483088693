import { combineReducers } from "redux";
import cartReducer, { ICartState } from "./cart/cart.reducer";
import checkoutReducer, { ICheckoutState } from "./checkout/checkout.reducer";
import filterReducer, { IFilterState } from "./filter/filter.reducer";
import loaderReducer, { ILoaderState } from "./loader/loader.reducer";
import miscReducer, { IMiscState } from "./misc/misc.reducer";
import productReducer, { IProductState } from "./product/product.reducer";
import translationReducer from "@redux/translation/translation.reducer";
import cartDataReducer from "@redux/cart/cart-content.reducer";
import { ICartDataState } from "@shared/types/cart-content.types";
import { TContentTranslation } from "@shared/types/translations.types";
import scenariosReducer, { IGetScenarios } from "./scenarios/scenarios.reducer";
import { HYDRATE } from "next-redux-wrapper";
import { PayloadAction, Reducer } from "@reduxjs/toolkit";
import modalReducer, { IModalState } from "./modal/modal.reducer";
import loaderCheckoutReducer, { ILoaderCheckoutState } from "./loader/loaderCheckout.reducer";

type ReduxStore = {
	cart: Reducer<ICartState>;
	checkout: Reducer<ICheckoutState>;
	filter: Reducer<IFilterState>;
	loader: Reducer<ILoaderState>;
	loaderCheckout: Reducer<ILoaderCheckoutState>;
	misc: Reducer<IMiscState>;
	product: Reducer<IProductState>;
	translations: Reducer<TContentTranslation<string>>;
	cartData: Reducer<ICartDataState>;
	scenarios: Reducer<IGetScenarios>;
	modal: Reducer<IModalState>;
};

export type RootState = ReturnType<typeof rootReducer>;

export const rootReducer = combineReducers<ReduxStore>({
	cart: cartReducer,
	checkout: checkoutReducer,
	filter: filterReducer,
	loader: loaderReducer,
	loaderCheckout: loaderCheckoutReducer,
	misc: miscReducer,
	product: productReducer,
	translations: translationReducer,
	cartData: cartDataReducer,
	scenarios: scenariosReducer,
	modal: modalReducer,
});

export type THydrateAction = PayloadAction<ReduxStore, typeof HYDRATE>;
