import { ICartLine, ICartLineElement } from "@shared/types/cart.types";
import { IPriceData } from "@shared/types/product.types";

interface IRemoveFromCart {
	cartLines?: ICartLine[];
	cartStateUpdate: ICartLine[];
}

interface IUpdateQuantity {
	cartLines: ICartLine[];
	itemCode: number;
	optionSelected: number;
	result?: number;
}

interface IChooseQuantityValue extends Omit<IUpdateQuantity, "cartLines"> {
	line: ICartLine;
}

export const findOriginalPrice = (priceForFront: Partial<IPriceData>) => {
	if (!priceForFront) return null;

	const { showSlash, slashedPrice, finalPrice } = priceForFront;

	if (showSlash) {
		return slashedPrice ? Number(slashedPrice).toFixed(2) : null;
	}

	return finalPrice ? Number(finalPrice).toFixed(2) : null;
};

export const buildDataLayerProductDetails = (line: ICartLine) => {
	return {
		quantity: line?.quantity,
		id: line?.itemCode?.toString(),
		supermodel: {
			id: line?.superModel?.toString(),
		},
		model: {
			id: line?.modelCode?.toString(),
		},
		price: {
			currency: "CAD",
			original: findOriginalPrice(line?.selectedArticleInfo?.priceForFront),
			current: line?.unitPrice?.toFixed(2),
		},
		brand: line?.brand,
	};
};

export const buildDataLayerCartDetails = (cartLines?: ICartLine[]) => {
	return cartLines?.map((line) => {
		return buildDataLayerProductDetails(line);
	});
};

//This will be modified / deleted when backend will update the response
//TODO if the function is not deleted and just modified, the correspondent test should be done

export const buildDataLayerCart = (cartLines?: ICartLineElement[]) => {
	return cartLines?.map((line) => {
		return {
			cartLineIde: line?.cartLineIde,
			itemCode: line?.itemCode,
			modelCode: line?.modelCode,
			quantityAdded: line?.quantityAdded,
			quantityAvailable: line?.quantityAvailable,
		};
	});
};

export const chooseQuantityValue = ({
	itemCode,
	line,
	optionSelected,
	result,
}: IChooseQuantityValue) => {
	if (result) {
		return Math.abs(result);
	}

	if (line?.itemCode === itemCode) {
		return optionSelected;
	}

	return line?.quantity;
};

export const buildDataLayerUpdateQuantity = ({
	itemCode,
	cartLines,
	optionSelected,
	result,
}: IUpdateQuantity) => {
	return cartLines?.map((line) => {
		return {
			quantity: chooseQuantityValue({ line, itemCode, optionSelected, result }),
			id: line?.itemCode?.toString(),
			supermodel: {
				id: line?.superModel,
			},
			model: {
				id: line?.modelCode?.toString(),
			},
			price: {
				currency: "CAD",
				original: findOriginalPrice(line?.selectedArticleInfo?.priceForFront),
				current: line?.unitPrice?.toFixed(2),
			},
			brand: line?.brand,
		};
	});
};

export const filterOutCartLine = ({ cartLines, cartStateUpdate }: IRemoveFromCart) => {
	return cartLines?.filter((cartLine) => cartLine?.itemCode !== cartStateUpdate[0]?.itemCode);
};

export const findCartLineById = (cartLines: ICartLine[], id: string) => {
	return cartLines?.find((line) => line?.cartLineId === id);
};

export const buildDatalayerRemoveFromCart = ({ cartLines, cartStateUpdate }: IRemoveFromCart) => {
	const filteredCartLines = filterOutCartLine({ cartLines, cartStateUpdate });
	return filteredCartLines?.map((line) => {
		return buildDataLayerProductDetails(line);
	});
};
