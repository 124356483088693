export enum EFulfillmentType {
	StoreDelivery = "STORE_DELIVERY",
	StorePickup = "STORE_PICKUP",
	UserDelivery = "USER_DELIVERY",
}

export enum EFulfillmentOption {
	Doorstep = "DOORSTEP",
	InHouse = "IN_HOUSE",
	InHouseAssembly = "IN_HOUSE_ASSEMBLY",
	Mailbox = "MAILBOX",
	SignatureRequired = "SIGNATURE_REQUIRED",
	Standard = "STANDARD",
	TrackedDelivery = "TRACKED_DELIVERY",
}

export enum EDay {
	Sunday,
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday,
}

export enum EOfferStatus {
	Complete = "COMPLETE",
	Incomplete = "INCOMPLETE",
}
