import { IDataLayerLayoutFormat } from "../datalayer.types";

export const getLocalizedDataLayerInformation = (locale: string) => {
	return locale === "fr"
		? { locale: "fr-FR", date_format_lite: "d/m/Y", date_format_full: "d/m/Y H:i:s", id: 1 }
		: { locale: "en-US", date_format_lite: "m/d/Y", date_format_full: "m/d/Y H:i:s", id: 2 };
};

export const buildDataLayerCurrency = () => ({
	name: "Canadian Dollar",
	iso_code: "CAD",
	iso_code_num: "124",
	sign: "$",
});

export const buildLayoutDataLayer = ({
	canonical,
	pageType,
	region,
	gtmBreadcrumbs,
	locale,
	additionalInfo,
}: IDataLayerLayoutFormat) => {
	const currency = buildDataLayerCurrency();
	const language = { name: locale };
	let dataLayerBreadcrumbs;

	try {
		dataLayerBreadcrumbs = JSON.parse(gtmBreadcrumbs as string);
	} catch {
		dataLayerBreadcrumbs = undefined;
	}

	return {
		canonical,
		type: pageType,
		region,
		breadcrumb: dataLayerBreadcrumbs,
		currency,
		language,
		iso_code: locale,
		language_code: `${locale}-CA`,
		is_rtl: 0,
		...getLocalizedDataLayerInformation(locale),
		...additionalInfo,
	};
};
