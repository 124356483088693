import { protected as protectedRoutes } from "@config/routes";
import { locales } from "@config/languages";

export const deleteUrlParameter = (url: string) => url?.split("?")[0];

export const appendUrlHashParam = (
	absoluteUrl: string,
	parameters: Record<string, string | undefined>
) => {
	try {
		const href = new URL(absoluteUrl);
		const paramsObject = { ...parameters };
		for (const key in paramsObject) {
			if (!paramsObject[key]) {
				delete paramsObject[key];
			}
		}

		const params = new URLSearchParams(paramsObject as Record<string, string>);

		return params.toString()
			? `${href.origin}${removeTrailingSlash(href.pathname)}#/?${params.toString()}`
			: removeTrailingSlash(href.toString());
	} catch {
		console.error("Could not return a valid url");
		return absoluteUrl;
	}
};

export const addQueryParams = (
	baseURL: string,
	params: { [key: string]: string | string[] | undefined }
): string => {
	const url = new URL(baseURL);

	for (const key in params) {
		const value = params[key];
		if (!value) continue;
		if (Array.isArray(value)) {
			for (const val of value) {
				url.searchParams.append(key, val);
			}
		} else {
			url.searchParams.append(key, value);
		}
	}

	return url.toString();
};

export const removeTrailingSlash = (path: string) => {
	return path?.replace(/(\/)*$/, "");
};

export const removeLeadingSlash = (path: string) => {
	return path?.replace(/^(\/)*/, "");
};

export const getUrlHashParam = (key: string) => {
	const hash = window.location.hash;
	const params = hash.replace("#/", "");
	const urlParams = new URLSearchParams(params);
	return urlParams.get(key);
};

export const removeLocaleFromPathname = (pathname: string) => {
	const regex = new RegExp(`^/(${locales.join("|")})/`);
	const match = regex.exec(pathname)?.[0];

	return match ? pathname.replace(match, "/") : pathname;
};

export const isProtectedRoute = (pathname: string) => {
	const cleanPathname = removeLocaleFromPathname(pathname);
	return protectedRoutes.some((route) => cleanPathname.startsWith(route));
};

export const isRedirectSignIn = (pathname: string) => {
	const cleanPathname = removeLocaleFromPathname(pathname);
	return protectedRoutes.some(
		(route) => cleanPathname.startsWith(route) || cleanPathname.startsWith("/cart")
	);
};
