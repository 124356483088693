import { ECookieName, getCookie } from "./cookie.utils";
import { IncomingMessage } from "node:http";
import { province } from "@config/misc";

export const getProvinceGeoCookie = (req?: IncomingMessage) => {
	return getCookie(ECookieName.ProvinceGeo, { req });
};

export const isValidUserProvince = (provinceCode?: string) => {
	return provinceCode && provinceCode !== "undefined";
};

export const getRegion = async (req?: IncomingMessage): Promise<string> => {
	return getCookie(ECookieName.ProvinceGeo, { req }) || province;
};
