import React from "react";
import getConfig from "next/config";
import Head from "next/head";
import { EFeatureFlagIdentifier } from "@shared/featureFlag/featureFlag.query";
import { useFeatureFlag } from "@hooks/useFeatureFlag";

const {
	publicRuntimeConfig: {
		klarnaSdk: { baseUrl, clientId, environment },
	},
} = getConfig();

const KlarnaSdk = () => {
	const isKlarnaEnabled = useFeatureFlag(EFeatureFlagIdentifier.Klarna);

	return (
		<>
			{isKlarnaEnabled && (
				<Head>
					<script
						async
						data-client-id={clientId}
						data-environment={environment}
						data-testid="KlarnaSdk_script"
						src={baseUrl}
					/>
				</Head>
			)}
		</>
	);
};

export default KlarnaSdk;
