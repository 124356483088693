import { isEmpty } from "lodash";
import { findOriginalPrice } from "@shared/datalayer/cart-datalayer/cart-datalayer";
import { IArticle, IPriceData } from "@shared/types/product.types";
import { EAvailabilityStatus, IAvailabilityProduct } from "@shared/availability/types";

interface IBuildAddToCart {
	superModel: string;
	articleId: number;
	brand: string;
	modelId: number;
	price: IPriceData;
	quantity: number;
}

export const buildDataLayerArticleReordering = (
	selectedArticleId: number,
	dataLayerArticles?: IDataLayerArticle[]
) => {
	let articleSelected = {};
	const articlesReordering = [];
	dataLayerArticles?.map((dataLayerArticle) => {
		if (dataLayerArticle?.id === selectedArticleId) {
			articleSelected = dataLayerArticle;
		} else {
			articlesReordering.push(dataLayerArticle);
		}
	});
	!isEmpty(articleSelected) && articlesReordering.unshift(articleSelected);
	return articlesReordering;
};

export const buildDataLayerAddToCart = ({
	superModel,
	articleId,
	brand,
	modelId,
	price,
	quantity,
}: IBuildAddToCart) => {
	return [
		{
			quantity,
			id: articleId?.toString(),
			supermodel: {
				id: superModel,
			},
			model: {
				id: modelId?.toString(),
			},
			availability: {
				warehouse: EAvailabilityStatus.InStock,
			},
			price: {
				currency: "CAD",
				original: findOriginalPrice(price),
				current: price?.finalPrice?.toFixed(2),
			},
			brand: brand,
		},
	];
};

export const findProductsObject = () => {
	return window.dataLayer?.find((element) => element.products);
};

export const findArticle = (availabilityArticles: IAvailabilityProduct[], article: IArticle) => {
	return availabilityArticles?.find(({ skuId }) => skuId === article?.sku);
};
