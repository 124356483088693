import type { ReactNode } from "react";
import type {
	Options,
	RenderMark,
	RenderNode,
	RenderText,
} from "@contentful/rich-text-react-renderer";
import { type Block, type Inline, INLINES, type Text } from "@contentful/rich-text-types";

import type { IBlockCarouselCflData } from "@components/ContentfulComponents/BlockCarousel/type";
import type { IContentBlockCflData } from "@components/ContentfulComponents/BlockContentBlock/type";
import type { ITwoColumnsCflData } from "@components/ContentfulComponents/BlockTwoColumnsContent/types";
import type { IAssetCflData } from "@components/ContentfulComponents/ComponentAsset/type";
import type { IAssetWithTextCflData } from "@components/ContentfulComponents/ComponentAssetWithText/type";
import type { ICarouselItemCflData } from "@components/ContentfulComponents/ComponentCarouselItem/type";
import type { IHighlightedTextCflData } from "@components/ContentfulComponents/ComponentHighlightedText/type";
import type { IJsonCflData } from "@components/ContentfulComponents/ComponentJson/type";
import type { ILinkCflData } from "@components/ContentfulComponents/ComponentLink/type";
import type { IProductLabelCflData } from "@components/ContentfulComponents/ComponentProductLabel/type";
import type { IQaCflData } from "@components/ContentfulComponents/ComponentQA/type";
import type { IReferenceCflData } from "@components/ContentfulComponents/ComponentReference/type";
import type { IRichTextCflData } from "@components/ContentfulComponents/ComponentRichText/type";
import type { ITextAssetAndCtasCflData } from "@components/ContentfulComponents/ComponentTextAssetAndCtas/type";
import type { ITextWithLinkCflData } from "@components/ContentfulComponents/ComponentTextWithLink/type";
import type { ITranslationKeyCflData } from "@components/ContentfulComponents/ComponentTranslationKey/type";
import type { IMultiContentPageCflData } from "@components/ContentfulComponents/ComposeMultiContent/type";
import {
	EContentfulPageType,
	EPageTypenames,
} from "@components/ContentfulComponents/ComposePage/type";
import type { ITextOnlyPageCflData } from "@components/ContentfulComponents/ComposeTextOnly/type";
import { defaultLocale, defaultSiteLocale } from "@config/languages";
import { replaceAllBreakSpaces } from "@shared/text/text.utils";
import { EBlockTypenames, EComponentTypenames, type IFormatRichText } from "./contentful.types";
import type { TTranslatableContent } from "./translations.utils";

type TContent = TTranslatableContent | IReferenceCflData;

// GraphQL
export const renderTextOptions = (text: string, additionalRenderTextOptions?: RenderText) => {
	if (!text) return null;
	const nonBreakingSymbolText = replaceAllBreakSpaces(text);
	if (!additionalRenderTextOptions) {
		return nonBreakingSymbolText;
	} else {
		return additionalRenderTextOptions(nonBreakingSymbolText);
	}
};

export const getTargetBlankLinkOptions = (
	additionalRenderNodeOptions: RenderNode,
	additionalRenderMarkOptions?: RenderMark,
	additionalRenderTextOptions?: RenderText
): Options => ({
	renderNode: {
		[INLINES.HYPERLINK]: (node, children) => {
			const regex = /{{showDidomi}}/;
			const match = node?.data?.uri?.match(regex);

			if (match) {
				const label = node.content?.[0];
				if (isText(label))
					return (
						<button
							className="text-black underline"
							onClick={() => window.Didomi?.preferences.show()}
						>
							{label.value}
						</button>
					);
			}

			return (
				<span>
					{node?.data?.uri ? (
						<a href={node.data.uri} target="_blank" rel="noreferrer">
							{children}
						</a>
					) : (
						children
					)}
				</span>
			);
		},
		...additionalRenderNodeOptions,
	},
	renderMark: {
		...additionalRenderMarkOptions,
	},
	renderText: (text: string) => renderTextOptions(text, additionalRenderTextOptions),
});

export const convertRelOptions = (relOptions?: string[]) => {
	return relOptions?.join(" ");
};

export const convertTargetOptions = (targetOptions?: string) => {
	return targetOptions || "_self";
};

// Type guards

/** COMPONENTS */

//TODO: what if assset is undefined
export const isAsset = (asset: TContent): asset is IAssetCflData =>
	asset.__typename === EComponentTypenames.Asset;

export const isAssetWithText = (assetWithText: TContent): assetWithText is IAssetWithTextCflData =>
	assetWithText.__typename === EComponentTypenames.AssetWithText;

export const isCarouselItem = (carouselItem: TContent): carouselItem is ICarouselItemCflData =>
	carouselItem.__typename === EComponentTypenames.CarouselItem;

export const isHighlightedText = (
	highlightedText: TContent
): highlightedText is IHighlightedTextCflData =>
	highlightedText.__typename === EComponentTypenames.HighlightedText;

export const isJson = (json: TContent): json is IJsonCflData =>
	json.__typename === EComponentTypenames.Json;

export const isLink = (link: TContent): link is ILinkCflData =>
	link.__typename === EComponentTypenames.Link;

export const isQa = (content: TContent): content is IQaCflData =>
	content.__typename === EComponentTypenames.QA;

export const isRichText = (richText: TContent): richText is IRichTextCflData =>
	richText.__typename === EComponentTypenames.RichText;

export const isReference = (content: TContent): content is IReferenceCflData =>
	content.__typename === EComponentTypenames.Reference;

export const isTextAssetCtas = (content: TContent): content is ITextAssetAndCtasCflData =>
	content.__typename === EComponentTypenames.TextAssetCtas;

export const isTextWithLink = (content: TContent): content is ITextWithLinkCflData =>
	content.__typename === EComponentTypenames.TextWithLink;

export const isTranslationKey = (translation: TContent): translation is ITranslationKeyCflData =>
	translation.__typename === EComponentTypenames.TranslationKey;

export const isBlockCarousel = (content: TContent): content is IBlockCarouselCflData =>
	content?.__typename === EBlockTypenames.Carousel;

/** END - COMPONENTS */

/** BLOCKS */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isContentBlock = (contentBlock: TContent): contentBlock is IContentBlockCflData<any> =>
	contentBlock.__typename === EBlockTypenames.ContentBlock;

export const isTwoColumn = (content: TContent): content is ITwoColumnsCflData =>
	content.__typename === EBlockTypenames.TwoColumns;

/** END - BLOCKS */

/** PAGES */
export const isMultiContent = (
	pageContent: IMultiContentPageCflData | ITextOnlyPageCflData
): pageContent is IMultiContentPageCflData =>
	pageContent.__typename === EPageTypenames.MultiContent;

export const isTextOnly = (
	pageContent: IMultiContentPageCflData | ITextOnlyPageCflData
): pageContent is ITextOnlyPageCflData => pageContent.__typename === EPageTypenames.Text;

export const isContentfulPageType = (pageContent: string): pageContent is EContentfulPageType =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.values(EContentfulPageType).includes(pageContent as any);

/** END - PAGES */

export const convertDefaultLocale = (locale: string) => {
	return locale === defaultSiteLocale ? defaultLocale : locale;
};

export const filterLabelsByModelId = ({
	labels,
	modelId,
}: {
	modelId?: number;
	labels?: IProductLabelCflData[];
}) => {
	if (!modelId || !labels) return;
	return labels.filter((label) => label?.mappingId?.mappingCodes?.includes(`${modelId}`));
};

export const removeRichTextEmptyChildren = ({
	element,
	children,
}: {
	element: ReactNode;
	children: ReactNode;
}) => {
	const isEmptyElement = children?.toString().trim() === "";
	return isEmptyElement ? null : element;
};

export const RichTextGenericElement = ({ children, Tag, className }: IFormatRichText) => {
	return <Tag className={className ?? ""}>{children}</Tag>;
};

export const formatFilledRichTextElement = ({ children, Tag, className }: IFormatRichText) => {
	const element = RichTextGenericElement({ children, Tag, className });
	return removeRichTextEmptyChildren({ element, children });
};

export const isText = (param: Block | Inline | Text): param is Text => {
	return param.nodeType === "text";
};
