import getOrderSummary from "./order-summary.use-case";
import { selectScenarioId } from "@redux/scenarios/scenarios.selector";
import { createAsyncThunk, isRejected } from "@reduxjs/toolkit";
import { ISelectedScenario, IUpdateOptionError } from "@shared/types/cart-content.types";
import { RootState } from "@redux/reducer";
import axios from "axios";
import { updateOptionErrors, updateScenario } from "@redux/cart/use-case/use-case.utils";
import { EFulfillmentType } from "@shared/types/delivery.types";
import { globalDataLayer } from "@shared/datalayer/DataLayer";
import { setSelectedFulfillers } from "../cart-content.reducer";
import { EDataLayerEventType } from "@shared/datalayer/datalayer.types";

export interface ISetDeliveryOption {
	containerId: string;
	deliveryId: string;
	deliverySlotId: string;
	offerId: string;
	shippingId: string;
	scenarioId: string;
	locale: string;
	parcelIndex: number;
	name: string;
	shortDescription: string;
}

const setDeliveryOption = createAsyncThunk<
	{ scenario: ISelectedScenario; errors: IUpdateOptionError[] },
	ISetDeliveryOption,
	{ rejectValue: IUpdateOptionError[]; state: RootState }
>("cart/setDeliveryOption", async (props, { getState, rejectWithValue, dispatch }) => {
	try {
		await axios.put("/api/scenario/select-delivery-option", {
			containerId: props.containerId,
			deliveryId: props.deliveryId,
			deliverySlotId: props.deliverySlotId,
			scenarioId: props.scenarioId,
		});
		const scenarioId = selectScenarioId(getState());
		const result = await dispatch(getOrderSummary({ scenarioId }));

		if (isRejected(result))
			return rejectWithValue(
				updateOptionErrors({ getState, props, errorType: EFulfillmentType.UserDelivery })
			);

		const {
			cartData: { selectedScenario },
		} = getState();

		globalDataLayer.customDataLayer.dispatchData({
			event: EDataLayerEventType.CheckoutSelectOption,
			checkout: {
				step: "fulfillment",
				fulfillmentOption: "home-delivery",
				option: "select fulfillment method",
			},
		});

		dispatch(
			setSelectedFulfillers({
				type: EFulfillmentType.UserDelivery,
				parcelIndex: props.parcelIndex,
				name: props.name.toLowerCase(),
				shortDescription: props.shortDescription,
				id: props.deliveryId,
			})
		);

		return {
			scenario: updateScenario({
				fulfillmentType: EFulfillmentType.UserDelivery,
				option: props,
				selectedScenario,
			}),
			errors: updateOptionErrors({ getState, props }),
		};
	} catch {
		return rejectWithValue(
			updateOptionErrors({ getState, props, errorType: EFulfillmentType.UserDelivery })
		);
	}
});

export default setDeliveryOption;
