import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import type { IAddressInputData } from "@components/Checkout/Delivery/How/Address/Address.type";
import { selectSelectedScenario } from "@redux/cart/cart-content.selector";
import {
	ECheckoutError,
	type ISubmitCheckout,
	type TCheckoutError,
} from "@redux/checkout/type/checkout.type";
import type { RootState } from "@redux/reducer";
import getScenario from "@redux/scenarios/use-case/scenario.use-case";
import { getUrlSaveAddresses } from "@shared/address/address.utils";
import type { IAvailabilityProduct } from "@shared/availability/types";
import { getCartItemsScenario, type ISaveAddress } from "@shared/checkout/checkout.utils";
import type { IParcel } from "@shared/types/containers.types";
import { EFulfillmentType } from "@shared/types/delivery.types";
import type { IPickupPointAddress } from "@shared/types/pickupPoints.type";

interface IGetContainerData {
	shipping?: IAddressInputData;
	containers: ISubmitContainer[];
}

interface ISubmitContainer {
	container_id: string;
	delivery_id: string;
	delivery_slot_id: string;
	fulfillment_type: EFulfillmentType;
	pickupPointAddress?: IPickupPointAddress;
}

interface IGetSkusError {
	skuArray: string[];
	availabilities: (IAvailabilityProduct | undefined)[];
}

const saveAddress = async (address: IAddressInputData) => {
	const urlApi = getUrlSaveAddresses(address.address_id);

	const { data } = await axios.post<ISaveAddress>(urlApi, {
		address: { ...address, pincode: address.pincode.replace(/\s*/g, "") },
	});

	return data;
};

const getSkusError = ({ skuArray, availabilities }: IGetSkusError) => {
	const skusError = [...skuArray];

	availabilities.forEach((skuAvailability) => {
		const { quantity, skuId } = skuAvailability || {};

		if (!quantity || !skuId) return;

		if (skusError.includes(skuId)) {
			const index = skusError.indexOf(skuId);

			skusError.splice(index, 1);
		}
	});

	return skusError;
};

const getAddressId = async (address?: IAddressInputData) => {
	if (!address) return;

	return address.address_line_1 && !address.address_id
		? (await saveAddress(address)).address_id
		: address.address_id;
};

export const getContainerData = ({ shipping, containers }: IGetContainerData) => {
	return containers.map((container) => {
		let deliveryAddress = {
			phone: shipping?.mobile,
			email: shipping?.email,
			name: shipping?.last_name,
			givenName: shipping?.first_name,
			city: shipping?.city,
			street1: shipping?.address_line_1 && `${shipping.address_line_1} ${shipping.address_line_2}`,
			postalCode: shipping?.pincode,
			state: shipping?.territory,
			country: "CA",
		};

		if (container.fulfillment_type === EFulfillmentType.StorePickup) {
			deliveryAddress = {
				...deliveryAddress,
				city: container.pickupPointAddress?.city,
				street1: container.pickupPointAddress?.street1,
				postalCode: container.pickupPointAddress?.postcode,
				state: container.pickupPointAddress?.province,
				country: "CA",
			};
		}

		return {
			...container,
			deliveryAddress,
		};
	});
};

const submitCheckout = createAsyncThunk<
	boolean,
	ISubmitCheckout,
	{ rejectValue: TCheckoutError; state: RootState }
>("checkout/submitCheckout", async ({ data, locale }, { rejectWithValue, getState, dispatch }) => {
	const ApiCallDelayInSec = 15;
	const parcels = getState()?.scenarios?.data as IParcel;
	const now = new Date().getTime();
	const { cartLines = [], cartId } = getState().cartData.data || {};

	const cartInfos = {
		cartId,
		items: getCartItemsScenario(cartLines),
	};
	if (parcels?.expiry && (parcels.expiry - ApiCallDelayInSec) * 1000 < now) {
		return rejectWithValue({ type: ECheckoutError.Expired });
	}
	try {
		const shippingAddressId = await getAddressId(data.shipping);
		const billingAddressId = await getAddressId(data.billing);

		await axios.put("/api/cart/address", {
			shippingAddressId,
			billingAddressId: billingAddressId || shippingAddressId,
		});

		const { skuConcat, skuArray } = cartLines.reduce<{ skuConcat: string; skuArray: string[] }>(
			(acc, { sku }) => {
				const { skuConcat } = acc;

				acc.skuConcat = skuConcat ? `${skuConcat},${sku}` : sku;
				acc.skuArray.push(sku);

				return acc;
			},
			{ skuConcat: "", skuArray: [] }
		);

		const { data: availabilities } = await axios.get<Partial<IAvailabilityProduct[]>>(
			`/api/availability?skus=${skuConcat}`
		);

		const skusError = availabilities ? getSkusError({ skuArray, availabilities }) : skuArray;

		if (skusError.length > 0) {
			window.location.href = `/${locale}/cart`;
			return rejectWithValue({ type: ECheckoutError.Availability, data: skusError });
		}

		const selectedScenario = selectSelectedScenario(getState());

		const containers = getContainerData({
			shipping: data.shipping || data.billing,
			containers: selectedScenario?.shippings?.[0]?.containers || [],
		});

		const { status } = await axios.post(
			"/api/fulfillment-address",
			{
				scenario_id: selectedScenario?.scenario_id,
				shipping_id: selectedScenario?.shippings?.[0]?.shipping_id,
				offer_id: selectedScenario?.shippings?.[0]?.offer_id,
				containers,
			},
			{
				validateStatus: function (responseStatus: number) {
					return (responseStatus >= 200 && responseStatus < 300) || responseStatus === 404;
				},
			}
		);

		if (status === 404) {
			await dispatch(getScenario({ cartInfos, locale }));
			return rejectWithValue({ type: ECheckoutError.Expired });
		}

		return true;
	} catch (error) {
		return rejectWithValue({ type: ECheckoutError.Unknown });
	}
});

export default submitCheckout;
