import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { INonHomologatedScenario } from "@shared/types/scenarios.types";
import { IParcel } from "@shared/types/containers.types";
import { ICartItemsScenario } from "@shared/types/cart.types";
import { isNonHomologatedProductsError } from "@shared/scenarios/scenarios.utils";

const getScenario = createAsyncThunk<
	IParcel | INonHomologatedScenario,
	{ cartInfos: { cartId: string; items: ICartItemsScenario[] }; locale: string },
	{ rejectValue: { error: string; items?: string[] } }
>("cart/getScenario", async ({ cartInfos, locale }, { rejectWithValue }) => {
	try {
		const { data } = await axios.post("/api/scenario", { cartInfos, locale });
		return data;
	} catch (error) {
		if (isNonHomologatedProductsError(error)) {
			return rejectWithValue({
				error: error.response?.data.error ?? "Non homologated scenario",
				items: error.response?.data.items ?? [],
			});
		}
		return rejectWithValue({ error: "Oops there seems to be an error" });
	}
});

export default getScenario;
