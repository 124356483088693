import { createSlice } from "@reduxjs/toolkit";
import submitCheckout from "@redux/checkout/use-case/submitCheckout.use-case";
import { ECheckoutError } from "@redux/checkout/type/checkout.type";

export interface IModalState {
	type: ECheckoutError | null;
}

const initialState: IModalState = {
	type: null,
};

const { actions, reducer } = createSlice({
	name: "modal",
	initialState,
	reducers: {
		resetModalType() {
			return {
				type: null,
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(submitCheckout.rejected, (state, { payload }) => {
			if (payload?.type) {
				state.type = payload.type;
			}
		});
	},
});

export const { resetModalType } = actions;
export default reducer;
