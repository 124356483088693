import {
	deleteCookie,
	ECookieName,
	getCookie,
	MAX_AGE_COOKIE_CART,
	setCookie,
	TCookieContextRequest,
	TCookieContextResponse,
} from "@utils/cookie.utils";

export const setCartItemsCountInCookies = (
	cartItemsCount: number,
	appCtx?: TCookieContextResponse
) => {
	setCookie(ECookieName.CartItemsCount, cartItemsCount?.toString(), appCtx, {
		maxAge: MAX_AGE_COOKIE_CART,
		path: "/",
	});
};

export const getCartItemsCountInCookies = () => {
	return Number(getCookie(ECookieName.CartItemsCount));
};

export const isCartItemsCountInCookies = () => {
	return !isNaN(getCartItemsCountInCookies());
};

const deleteCartItemsCountInCookies = (appCtx?: TCookieContextResponse) => {
	deleteCookie(ECookieName.CartItemsCount, appCtx);
};

export const setCartIdInCookies = (cartId: string, appCtx?: TCookieContextResponse) => {
	setCookie(ECookieName.CartId, cartId, appCtx, {
		maxAge: MAX_AGE_COOKIE_CART,
		path: "/",
	});
};

export const getCartIdInCookies = (appCtx?: TCookieContextRequest) => {
	return getCookie(ECookieName.CartId, appCtx);
};

export const isCartIdInCookies = (appCtx?: TCookieContextRequest) => {
	const cookieCart = getCartIdInCookies(appCtx);
	return Boolean(cookieCart) && cookieCart !== "undefined" && cookieCart !== "null";
};

const deleteCartIdInCookies = (appCtx?: TCookieContextResponse) => {
	deleteCookie(ECookieName.CartId, appCtx);
};

export const deleteCartInfoInCookies = (appCtx?: TCookieContextResponse) => {
	deleteCartIdInCookies(appCtx);
	deleteCartItemsCountInCookies(appCtx);
};

export const setDisplayMergeCartsModalInCookies = (
	shouldDisplay: string,
	appCtx?: TCookieContextResponse
) => {
	setCookie(ECookieName.DisplayModalMergeCarts, shouldDisplay, appCtx);
};

export const getDisplayMergeCartsModalInCookies = (appCtx?: TCookieContextRequest) => {
	return getCookie(ECookieName.DisplayModalMergeCarts, appCtx);
};

export const deleteDisplayMergeCartsModalInCookie = (appCtx?: TCookieContextResponse) => {
	deleteCookie(ECookieName.DisplayModalMergeCarts, appCtx);
};
