import { IAddressInputData } from "@components/Checkout/Delivery/How/Address/Address.type";

export interface ISubmitCheckout {
	data: Partial<Record<"shipping" | "billing", IAddressInputData>>;
	locale: string;
}

export enum ECheckoutError {
	Unknown = "unknown",
	Availability = "availability",
	Expired = "Expired",
}

interface IAvailabilityError {
	type: ECheckoutError.Availability;
	data: string[];
}

interface IUnknownError {
	type: ECheckoutError.Unknown;
}

interface IExpiredError {
	type: ECheckoutError.Expired;
}

export type TCheckoutError = IAvailabilityError | IUnknownError | IExpiredError;
